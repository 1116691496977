import React from 'react';

const Card = ({ image, title, text }) => {
  return (
    <div className="relative sm:w-[100%] md:w-[100%] xl:w-[100%] lg:h-[330px] h-[390px] xl:h-[390px] ipad-pro:h-[400px] border border-gray-300 rounded-lg overflow-hidden flex flex-col items-center justify-between p-4 shadow-lg"> {/* Added shadow-lg */}
      <div className="flex flex-col items-center justify-center text-center flex-grow bg-opacity-50 rounded-b-lg">
        <img  src= {image} alt="card background" className="w-[20%] h-[20%] object-cover mb-10" />
        <h3 className="text-xl font-quicksand font-bold mb-2">{title}</h3>
        <p className="text-base">{text}</p>
      </div>
    </div>
  );
};
const CardContainer = () => {
    const cards = [
      { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/career-anchor.webp", title: 'Innovation', text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry. ' },
      { image:  "https://webkorps-app.s3.us-east-1.amazonaws.com/career-box.webp",  title: 'Collaboration', text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.' },
      { image:  "https://webkorps-app.s3.us-east-1.amazonaws.com/career-branch.webp", title: 'Accessibility', text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.' },
      { image:  "https://webkorps-app.s3.us-east-1.amazonaws.com/career-key.webp", title: 'Candour', text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry. ' },
    ];
    return (
      <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-4  ipad-pro:grid-cols-2 xl:gap-5  ipad-pro:gap-15  gap-10">
        {cards.map((card, index) => (
          <Card key={index} image={card.image} title={card.title} text={card.text} />
        ))}
      </div>
    );
  };
  export default CardContainer;