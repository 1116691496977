import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';

function JobOpeningList() {
  const [jobOpenings, setJobOpenings] = useState([]);

  useEffect(() => {
    fetch('https://be.webkorps.com:4202/v1/career_jobs') 
      .then(response => response.json())
      .then(data => setJobOpenings(data))
      .catch(error => console.error('Error:', error));
  }, []);

  const handleDelete = async (id) => {
    try {
      await fetch(`https://be.webkorps.com:4202/v1/career_jobs/${id}`, { method: 'DELETE' }); 
      setJobOpenings(jobOpenings.filter(job => job?.id !== id));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Header/>
<div className='flex justify-center p-32 h-screen'>
<div class="relative overflow-x-auto container justify-center m-auto h-full">
<div class="text-center mt-4 mb-4">
        <h2 class="text-1xl sm:text-2xl leading-normal font-extrabold tracking-tight text-gray-900 flex float-start">Job Opening List</h2>
       
        <button type="button" class="py-2.5 px-5 me-2 mb-2 shadow primary-color hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mr-4 float-end"> 
                 <Link to="/job-openings/create" >Create New Job Opening</Link>
        </button>
          </div>
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                job-title
                </th>
                <th scope="col" class="px-6 py-3">
                experience
                </th>
                <th scope="col" class="px-6 py-3">
                number-of-vacancy
                </th>
                <th scope="col" class="px-6 py-3">
                deadline
                </th>
                <th scope="col" class="px-6 py-3">
                location
                </th>
                <th scope="col" class="px-6 py-3">
                action
                </th>
            </tr>
        </thead>
        <tbody>
        {jobOpenings.map(job => (
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700" key={job.id}>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {job.job_title}
                </th>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {job.experience}
                </th><th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {job.number_of_vacancy}
                </th><th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {job.deadline}
                </th><th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {job.location}
                </th>
                <td class="px-6 py-4">
                    <Link to={`/job-openings/${job.id}`} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</Link>&nbsp;|&nbsp;
                    <button onClick={() => handleDelete(job.id)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</button>
                </td>
            </tr>
 ))}
        </tbody>
    </table>
</div>
</div>
<Footer/>
    </div>
  );
}

export default JobOpeningList;