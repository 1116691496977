const projectsData = [
    {
        id: 'assetpanda',
        title: 'Asset Panda',
        description: 'Asset Panda is an advanced asset tracking and management platform using microservices architecture for scalability and performance. Features mobile app integration for remote asset management and QR code scanning.',
        challenges: [
            { description: 'Microservices Integration: Ensuring seamless communication between microservices with secure API gateways.' },
            { description: 'Real-time Data Synchronization: Implementing Kafka-based event-driven architecture for instant data sync.' },
            { description: 'Scalability and Load Management: Scaling horizontally, optimizing database queries, using Redis caching.' },
            { description: 'QR Code Scanning Integration: Developing fast, accurate scanning optimized for various devices.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/asset.webp',
        solution: `Asset Panda’s team implemented a modular microservices architecture to facilitate flexibility and
scalability, supporting over 1 million users and up to 50,000 concurrent API calls. Real-time data
synchronization was achieved using Kafka, allowing the platform to process over 100,000 data sync
events per second, ensuring less than 1-second delays. With Redis caching, server load was reduced by
35%, enabling quick data access and a 60% reduction in query response times. The QR code scanning
feature was enhanced to process up to 500 scans per minute with 98% accuracy, significantly reducing
asset management time and improving overall efficiency.`,
        techStacks: ['Ruby on Rails', 'React JS', 'Docker', 'Kubernetes', 'AWS', 'Apache Kafka', 'Redis', 'PostgreSQL', 'Nginx', 'Jenkins', 'Git', 'Elasticsearch', 'GraphQL'],
    },
    {
        id: 'cryoport',
        title: 'Cryoport',
        description: 'Cryoport provides temperature-controlled supply chain solutions for life sciences, supporting biopharmaceuticals, reproductive medicine, and animal health.',
        challenges: [
            { description: 'Legacy Codebase: Complex refactoring needed for stability during transition.' },
            { description: 'User Experience Redesign: Extensive research for modern web standards.' },
            { description: 'Integration of New Technologies: Maintaining compatibility with existing systems.' },
            { description: 'Deployment Strategy: Minimizing downtime during upgrade.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/Cryoport.webp',
        solution: `Cryoport deployed the Cryoportal® platform with advanced thermal packaging and real-time monitoring
systems. This setup ensures·
Temperature Control: Achieved a 95%+ success rate in maintaining required temperature ranges
with specialized packaging. Live Monitoring: Provided real-time data on temperature, humidity, and other conditions, with an
accuracy of ±0.1°C, covering 1,000+ shipments monthly. Regulatory Automation: Reduced compliance risks by 85% through automated documentation and
regulatory checks, ensuring adherence to international standards. Logistics Optimization: Minimized operational costs by 30% and improved delivery accuracy to 98%,
leveraging predictive analytics and third-party integrations.`,
        techStacks: ['Ruby on Rails', 'Docker', 'Kubernetes', 'AWS', 'Apache Kafka', 'Redis', 'PostgreSQL', 'Nginx', 'Jenkins', 'Git', 'Elasticsearch', 'GraphQL'],
    },
    {
        id: 'cigna',
        title: 'Cigna',
        description: 'Fortune 15 global healthcare and insurance company serving 175 million customers worldwide.',
        challenges: [
            { description: 'Inefficient Claims Processing: Traditional system was slow and error-prone.' },
            { description: 'Data Management: Needed better patient data analysis.' },
            { description: 'Integration of AI Solutions: Compatibility with existing systems.' },
            { description: 'Change Management: Employee training and adoption.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/cigna.webp',
        solution: 'Adopted BRMS through Progress Corticon, leveraged AI for early detection of chronic conditions, implemented cloud-based solutions using AWS Redshift and Tableau, developed comprehensive training programs.',
        techStacks: ['React', 'Node.js', 'MongoDB', 'AWS', 'ElasticSearch', 'Jitsi'],
    },
    {
        id: 'sonichealthcare',
        title: 'Sonic Healthcare',
        description: 'Global healthcare company specializing in diagnostic services, established in 1987.',
        challenges: [
            { description: 'Legacy Systems: Outdated systems hindered data processing.' },
            { description: 'Data Integration: Seamless integration of various diagnostic technologies.' },
            { description: 'Scalability: Need for scalable solution maintaining service quality.' },
            { description: 'Change Management: Addressing employee concerns during transition.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/sonichelth.webp',
        solution: 'Updated IT infrastructure for modern diagnostic technologies, implemented AI-driven solutions, transitioned to cloud platforms for better data management, developed structured program for staff adaptation.',
        techStacks: ['React', 'Node.js', 'MongoDB', 'AWS', 'ElasticSearch', 'Jitsi'],

    },
    {
        id: 'canopie',
        title: 'Canopie',
        description: 'Mobile app for maternal mental health care, providing preventative resources.',
        challenges: [
            { description: 'User Engagement: Ensuring consistent app usage.' },
            { description: 'Data Privacy: Complying with HIPAA and SOC-2 Type 2 standards.' },
            { description: 'Feature Integration: Developing comprehensive therapy features.' },
            { description: 'Cultural Sensitivity: Addressing diverse populations.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/cannopieProj.webp',
        solution: 'Intuitive interface with daily engagement features, implemented HIPAA-compliant data protection, integrated digital screening tools and clinician-led classes, created culturally sensitive materials.',
        techStacks: ['React', 'Node.js', 'MongoDB', 'AWS', 'ElasticSearch', 'Jitsi'],
    },
    {
        id: 'medhost',
        title: 'MedHost',
        description: 'MEDHOST is a leading healthcare technology firm that has been providing IT solutions and services for hospitals and healthcare facilities for over 35 years. With a focus on integrated clinical and financial solutions, MEDHOST helps organizations streamline billing, manage health data, and increase workflow efficiency. The company serves more than 1,000 healthcare facilities across the globe, offering a fully integrated electronic health record (EHR) solution designed to enhance patient care and operational excellence.',
        challenges: [
            { description: 'Legacy Infrastructure: The existing IT infrastructure was outdated, making it difficult to support modern healthcare applications and services effectively.' },
            { description: 'Data Migration: Transitioning to cloud-based solutions required careful planning to migrate existing EHR data while ensuring compliance and data integrity.' },
            { description: 'Integration of New Technologies: Implementing advanced analytics and FHIR (Fast Healthcare Interoperability Resources) functionality demanded significant integration efforts with existing systems.' },
            { description: 'User Adoption: Ensuring that healthcare staff were trained and comfortable with new technologies was essential for maximizing the benefits of the upgrades.' }
        ],

        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/medhost.webp',
        solution: 'Cloud Migration: Assisted MEDHOST in migrating their EHR data to Amazon Web Services (AWS), leveraging Amazon HealthLake for enhanced data management and compliance with regulatory requirements. Automation of Processes: Developed automated workflows to streamline communication between patients and healthcare providers, improving overall service delivery. Integration of FHIR Standards: Enabled FHIR functionality on AWS to facilitate secure access to patient data and improve interoperability with other healthcare applications. Training Programs: Designed training initiatives for healthcare staff to ensure they could effectively utilize new systems and understand the benefits of the upgraded technology.',
        techStacks: ['Amazon Web Services', 'FHIR', 'HealthLake', 'Cloud'],
    },
    {
        id: 'canopie',
        title: 'Canopie',
        description: 'Canopie is a mobile app providing mental health support for new and expecting mothers, aiming to improve maternal-child health outcomes through accessible preventative care.',
        challenges: [
            { description: 'User Engagement: Ensuring high engagement levels among users for effective mental health management.' },
            { description: 'Data Privacy and Security: Protecting sensitive data with HIPAA and SOC-2 Type 2 compliance.' },
            { description: 'Integration of Features: Developing digital screenings, patient monitoring, and self-guided therapy.' },
            { description: 'Cultural Sensitivity: Designing content that resonates with diverse populations.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/cannopieProj.webp',
        solution: 'Developed an intuitive app with secure, culturally sensitive, personalized features for tracking mental health and providing support.',
        techStacks: ['React Native', 'Node.js', 'AWS', 'GraphQL', 'PostgreSQL', 'Twilio'],
    },
    {
        id: 'elearningapp',
        title: 'E Learning App',
        description: 'Confidential e-learning platform offering personalized learning paths, interactive content, and assessment tools to enhance educational outcomes.',
        challenges: [
            { description: 'Custom Development: Building from scratch with multimedia content and interactive features.' },
            { description: 'User Engagement: Designing for sustained user interaction and learning.' },
            { description: 'Data Security: Ensuring GDPR compliance and secure handling of user data.' },
            { description: 'AI-driven Personalization: Incorporating analytics and AI-based learning recommendations.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ourProject3.svg',
        solution: 'Developed a custom e-learning platform with multimedia content, AI-driven personalization, and robust data security.',
        techStacks: ['React', 'Node.js', 'MongoDB', 'AWS', 'ElasticSearch', 'Jitsi'],
    },
    {
        id: 'bhaibandhu',
        title: 'Bhai Bandhu',
        description: 'Bhai Bandhu is a B2B marketplace for Indian ethnic wear, connecting wholesalers and retailers with features like product catalogs, order management, and secure payments.',
        challenges: [
            { description: 'Custom Development: Building tailored solutions from scratch.' },
            { description: 'User Engagement: Ensuring regular use from wholesalers and retailers.' },
            { description: 'Data Security: Securing sensitive transactions and user information.' },
            { description: 'Feature Integration: Implementing order management, payment processing, and notifications.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/bhaibhandhu.webp',
        solution: 'Created a mobile and web app with order management, secure payments, and user-friendly interfaces for Indian ethnic wear wholesalers and retailers.',
        techStacks: ['React Native', 'Node.js', 'MongoDB', 'AWS', 'Stripe', 'Twilio'],
    },
    {
        id: 'styla',
        title: 'Styla',
        description: 'Styla provides integrated e-commerce solutions like PWAs, no-code page builders, and image optimization, aimed at simplifying storefront management for BigCommerce and Shopware platforms.',
        challenges: [
            { description: 'Custom Development: Building a fully integrated solution.' },
            { description: 'User Experience: Creating a simple yet powerful interface for non-technical users.' },
            { description: 'Performance Optimization: Ensuring fast load times and mobile performance.' },
            { description: 'Platform Integration: Integrating with established platforms like BigCommerce and Shopware.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/styla.webp',
        solution: 'Developed a PWA storefront, no-code page builder, image optimizer, and pre-render engine for a seamless e-commerce experience.',
        techStacks: ['React', 'Node.js', 'GraphQL', 'AWS', 'Netlify', 'Cloudinary', 'Shopify'],
    },
    {
        id: 'thefragranceshop',
        title: 'The Fragrance Shop',
        description: 'Mobile app for The Fragrance Shop, designed to enhance customer engagement and loyalty with features like MyTFS loyalty program, secure payment, and personalized recommendations.',
        challenges: [
            { description: 'User Experience Design: Creating a diverse customer-centric interface.' },
            { description: 'System Integration: Seamlessly integrating with existing systems.' },
            { description: 'Data Security: Protecting sensitive customer data.' },
            { description: 'Feature Development: Including product reviews, loyalty programs, and recommendations.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/tfs.webp',
        solution: 'Developed a mobile app with a user-friendly interface, loyalty program integration, secure payments, and personalized recommendations.',
        techStacks: ['React Native', 'Node.js', 'MongoDB', 'AWS', 'Stripe', 'Twilio'],
    },
    {
        id: 'pebble',
        title: 'Pebble',
        description: 'Custom app development for Pebble smartwatches with features like notifications, fitness tracking, and customizable watch faces to enhance the user experience.',
        challenges: [
            { description: 'Platform Limitations: Leveraging Pebble’s unique hardware features.' },
            { description: 'User Engagement: Providing an interactive yet simple interface.' },
            { description: 'Service Integration: Seamless integration with existing APIs.' },
            { description: 'Device Compatibility: Testing across multiple Pebble models.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/paybal.webp',
        solution: 'Developed a feature-rich app with real-time notifications, fitness tracking, and customizable watch faces, optimized for Pebble’s platform.',
        techStacks: ['C', 'Pebble SDK', 'Node.js', 'AWS', 'MongoDB'],
    },
    {
        id: 'mykiaapp',
        title: 'My Kia App',
        description: 'Standalone mobile app for Kia owners, integrating sales, service management, and loyalty programs to streamline the ownership experience.',
        challenges: [
            { description: 'Feature Integration: Combining sales, service, and rewards features.' },
            { description: 'User Experience: Ensuring ease of use for a diverse customer base.' },
            { description: 'Data Security: Protecting user information and ensuring compliance.' },
            { description: 'Real-Time Notifications: Implementing timely service and rewards notifications.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/mykiaweb.webp',

        solution: 'Developed a user-friendly app with integrated sales and service features, MyKia Rewards program, and real-time notifications.',
        techStacks: ['React Native', 'Node.js', 'GraphQL', 'AWS', 'MongoDB'],
    },
    {
        id: 'shreelaxmisteels',
        title: 'Shree Laxmi Steels',
        description: 'Shree Laxmi Steels is a prominent Indian steel manufacturing brand committed to delivering high-quality steel products. Webkorps developed a comprehensive CRM system tailored to track customer interactions, sales activities, and team performance.',
        challenges: [
            { description: 'Data Integration: Consolidating data from various sources into a single CRM system required meticulous planning and execution to ensure accuracy.' },
            { description: 'User Adoption: Ensuring that the sales team would effectively adopt and utilize the new CRM system was crucial for maximizing its benefits.' },
            { description: 'Customization Needs: Developing customizable dashboards and analytical tools that met the specific needs of Shree Laxmi Steels posed technical challenges.' },
            { description: 'Real-Time Analytics: Implementing real-time analytics capabilities to provide immediate insights into sales performance and customer interactions required robust backend integration.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/shreelaxmisteel.webp',
        solution: 'Webkorps developed a centralized CRM with customizable dashboards, real-time analytics, and provided user training to ensure adoption by the sales team.',
        techStacks: ['Node.js', 'React JS', 'MongoDB', 'AWS', 'Docker', 'Kubernetes'],
    },
    {
        id: 'paypal',
        title: 'PayPal',
        description: 'Webkorps collaborated with PayPal to enhance their payment platform, focusing on user experience, seamless transaction processes, and the integration of robust analytics tools for merchants.',
        challenges: [
            { description: 'Customizability: Developing payment forms that could be easily customized by merchants without requiring extensive technical knowledge.' },
            { description: 'User Experience: Ensuring that the new features provided a seamless and intuitive user experience across various devices was critical for adoption.' },
            { description: 'Security Compliance: Maintaining compliance with security standards while integrating new functionalities was paramount throughout the development process.' },
            { description: 'Integration with Existing Systems: Ensuring that new features integrated smoothly with PayPal’s existing infrastructure required careful planning and execution.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/paypalProject.webp',
        solution: `Webkorps has implemented a robust solution to enhance the payment processing experience for merchants. 
        Here’s a concise summary of the key components. Custom Payment Forms: Developed customizable credit and debit card payment forms to improve the checkout experience and align with merchants' branding. Streamlined Checkout Process: Optimized the checkout flow by integrating PayPal’s branded checkout, allowing for quicker transaction completion with customized card fields. Analytics Dashboard: Set up an advanced analytical system using Splunk and Kibana filters to provide merchants with insights into sales performance, customer behavior, and transaction trends. Additionally, migrated these dashboards to Datadog for continuous monitoring. Enhanced Security Features: Integrated PayPal’s Risk Assessment Service (RAAS) to improve transaction security. Created user-defined filters for merchants to establish custom risk rules, such as enforcing 3D Secure (3DS) authentication for high-value transactions and setting country-specific transaction limits. Real-Time Notifications: Implemented a notification system to alert merchants of abnormal activities, such as alerts when the threshold value of a specific component or API falls below 20%.`,
        techStacks: ['Node.js', 'React JS', 'AWS', 'Docker', 'MySQL', 'Redis', 'Nginx'],
    },
    {
        id: 'tonik',
        title: 'Tonik',
        description: 'Tonik Bank, a digital-only bank in the Philippines, collaborated with Webkorps to redesign their mobile application, focusing on improving UI/UX, scalability, and backend robustness while ensuring compliance with banking regulations.',
        challenges: [
            { description: 'User Experience Improvement: Redesigning the UI to enhance usability while ensuring a visually appealing layout was essential for increasing user engagement.' },
            { description: 'Backend Scalability: Developing a scalable backend architecture that could handle increased user traffic and support new functionalities posed significant technical challenges.' },
            { description: 'Data Security Compliance: Ensuring compliance with banking regulations and maintaining high standards of data security throughout the app\'s development was paramount.' },
            { description: 'Integration of New Features: Implementing new features such as enhanced analytics, personalized notifications, and improved transaction tracking required careful planning and execution.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/tonik.webp',
        solution: 'Webkorps implemented a modern UI/UX redesign, developed a scalable backend system, enhanced security features, and integrated new functionalities such as real-time transaction notifications and expenditure analysis.',
        techStacks: ['React Native', 'Node.js', 'AWS', 'PostgreSQL', 'Docker', 'Kubernetes'],
    },
    {
        id: 'acima',
        title: 'Acima',
        description: 'Acima is a lease-to-own service that allows customers to acquire products without needing traditional credit or loans. Webkorps partnered with Acima to enhance platform features and provide ongoing maintenance, ensuring a smooth and efficient user experience.',
        challenges: [
            { description: 'User Experience Improvement: Needed a more intuitive interface that simplifies the leasing process for customers and provides clear information about terms.' },
            { description: 'Feature Integration: Implementing advanced payment options, detailed product information, and improving communication between customers and retailers.' },
            { description: 'Regulatory Compliance: Ensuring all features complied with financial regulations and consumer protection laws.' },
            { description: 'System Scalability: Developing a solution that could handle increased traffic and transactions without compromising performance.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/acima.webp',
        solution: 'Webkorps redesigned Acima\'s platform, streamlined the approval workflow, integrated flexible payment options, developed an improved communication system, and provided ongoing platform maintenance.',
        techStacks: ['Node.js', 'React JS', 'AWS', 'MySQL', 'Redis', 'Docker', 'Kubernetes'],
    },
    {
        id: 'abb',
        title: 'ABB',
        description: 'ABB is a global leader in electrification and automation. Webkorps helped ABB implement a digital transformation strategy, integrating IoT technologies to enhance data visibility and operational efficiency.',
        challenges: [
            { description: 'Data Integration Issues: Integrating data from various IoT devices posed challenges due to differences in data formats and communication protocols.' },
            { description: 'Interoperability: Ensuring compatibility between legacy systems and new IoT solutions was critical.' },
            { description: 'Scalability: Developing a solution to handle increased data traffic from IoT devices.' },
            { description: 'Real-Time Data Processing: Implementing real-time analytics capabilities to process streaming data effectively.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ABB.webp',
        solution: `Webkorps implemented a comprehensive solution tailored to ABB's needs¶
 IoT. Integration Platform: Developed a unified platform that integrates various IoT devices,
allowing for seamless data collection and analysis across departments. Standardized Data Protocols: Established standardized communication protocols to ensure
consistent data formats across different IoT devices, facilitating easier integration and analysis. Real-Time Analytics Dashboard: Created an analytics dashboard that provides real-time insights
into operations, enabling quick decision-making based on up-to-date information. Enhanced Data Management Strategy: Implemented a robust data management strategy that
includes data cleansing processes to ensure high-quality data is available for analysis.vScalable Architecture: Designed the solution with scalability in mind, allowing ABB to expand its
IoT capabilities as needed without requiring significant infrastructure changes.`,
        techStacks: ['IoT', 'Node.js', 'AWS', 'Kafka', 'MongoDB', 'Docker', 'Kubernetes'],
    },
    {
        id: 'digikagaz',
        title: 'DigiKagaz',
        description: 'DigiKagaz is a platform revolutionizing document automation for legal professionals and individuals, simplifying the process of generating accurate documents. Webkorps developed this platform from scratch, providing a seamless user experience and integration with printing services.',
        challenges: [
            { description: 'Building from Scratch: Developing a robust platform from the ground up, requiring extensive planning and implementation.' },
            { description: 'User-Centric Design: Creating an intuitive interface that simplifies the document selection and input process.' },
            { description: 'Error Reduction Mechanisms: Implementing features to minimize manual entry errors while ensuring compliance with legal requirements.' },
            { description: 'Integration of Printing Services: Establishing a reliable connection with the Court Service Center for document printing.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/digikagaz.webp',
        solution: 'Webkorps developed DigiKagaz with an intuitive user interface, automated document generation, printing integration, and a continuous improvement framework for ongoing enhancements.',
        techStacks: ['Node.js', 'React JS', 'AWS', 'MongoDB', 'Docker', 'Kubernetes'],
    },
    {
        id: 'cloudshim',
        title: 'CloudShim',
        description: 'CloudShim was developed to simplify cloud management, providing users with visibility into their cloud environments to manage resources and control costs effectively. Webkorps partnered to build this comprehensive platform that optimizes cloud usage.',
        challenges: [
            { description: 'Complexity of Integration: Developing a platform that integrates with various cloud service providers while maintaining user-friendliness.' },
            { description: 'Real-Time Monitoring: Implementing real-time monitoring capabilities for tracking cloud usage and costs.' },
            { description: 'User-Centric Design: Ensuring a simple and intuitive interface accessible to both technical and non-technical users.' },
            { description: 'Scalability: Designing a solution that scales with increasing cloud usage.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/AWSobject.svg',
        solution: `Webkorps implemented a comprehensive solution tailored to Cloudhshim's needs. Unified Dashboard: Developed a centralized dashboard that provides users with real-time insights
into their cloud infrastructure, enabling easy tracking of resource usage and costs. Cost Optimization Tools: Integrated features that help users identify cost culprits, such as underutilized resources or excessive spending on specific services, allowing for informed decision-
making. Automated Reporting: Implemented automated reporting tools that generate detailed reports on
cloud usage and expenditures, simplifying financial oversight for organizations. User-Friendly Interface: Designed an intuitive interface that simplifies navigation and enhances user
engagement, making it accessible for all levels of technical expertise.`,
        techStacks: ['Node.js', 'React JS', 'AWS', 'Docker', 'Kubernetes'],
    },
    {
        id: 'scoutr',
        title: 'Scoutr',
        description: 'Scoutr is a platform designed to enhance talent management by connecting job seekers with roles that match their skills. Webkorps developed Scoutr to streamline hiring processes using advanced algorithms for accurate talent matching.',
        challenges: [
            { description: 'Data Integration: Aggregating data from diverse sources like resumes, job descriptions, and profiles.' },
            { description: 'Algorithm Development: Developing a matching algorithm that pairs candidates with jobs based on skills and aspirations.' },
            { description: 'User Experience: Ensuring an intuitive experience for both employers and job seekers.' },
            { description: 'Scalability: Ensuring the platform scales with growing user demand and data volume.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/scoutr.webp',
        solution: 'Webkorps developed an advanced matching algorithm, a unified user interface, a real-time analytics dashboard, and a feedback loop for continuous improvement.',
        techStacks: ['Node.js', 'React JS', 'AWS', 'MongoDB', 'Docker', 'Kubernetes'],
    },
    {
        id: 'cardinalhire',
        title: 'CardinalHire',
        description: 'CardinalHire is a recruitment platform that connects tech talent with leading companies. Webkorps partnered with CardinalHire to improve user engagement and develop features to streamline hiring processes.',
        challenges: [
            { description: 'Fragmented Recruitment Processes: Consolidating multiple recruitment tools into one streamlined platform.' },
            { description: 'Candidate Visibility: Ensuring qualified candidates are visible to hiring managers in a timely manner.' },
            { description: 'Data Management: Managing large volumes of candidate data while ensuring privacy compliance.' },
            { description: 'User Engagement: Developing strategies to keep employers and job seekers actively engaged on the platform.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/cardinal.webp',
        solution: 'Webkorps developed a centralized recruitment hub, accelerated candidate matching, enhanced job seeker visibility, and launched engagement strategies to drive platform participation.',
        techStacks: ['Node.js', 'React JS', 'AWS', 'MongoDB', 'Docker', 'Kubernetes'],
    },
    {
        id: 'owenscorning',
        title: 'Owens Corning - Innovating in Building Materials',
        description: 'Owens Corning is a leading American company specializing in the development and production of insulation, roofing, and fiberglass composites. Founded in 1935, the company is the world\'s largest manufacturer of fiberglass composites and is committed to innovation and sustainability.',
        challenges: [
            { description: 'Environmental Regulations: Increasingly stringent regulations around greenhouse gas emissions required innovation in manufacturing processes.' },
            { description: 'Market Competition: The rise of competitors necessitated a focus on product differentiation and brand loyalty.' },
            { description: 'Supply Chain Efficiency: Managing a complex global supply chain while optimizing production efficiency.' },
            { description: 'Consumer Awareness: Educating consumers about the benefits of fiberglass products over traditional materials.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/owenscorning.webp',
        solution: 'Increased funding for R&D led to the development of new blowing agent blends to reduce emissions, launched new insulation products using renewable materials, implemented advanced analytics tools for supply chain optimization, and developed marketing strategies to educate consumers.',
        techStacks: ['R&D', 'Sustainable Manufacturing', 'Analytics Tools', 'Marketing Strategies'],
    },
    {
        id: 'inkind',
        title: 'inKind - Enhancing Customer Loyalty for Local Businesses',
        description: 'inKind is an innovative platform designed to reward loyal customers while supporting local restaurants, allowing diners to earn cash back and exclusive rewards with every meal.',
        challenges: [
            { description: 'Fragmented Loyalty Programs: Existing systems involved multiple apps or cards, leading to disorganization among diners.' },
            { description: 'Customer Awareness: Many potential users were unaware of the benefits of dining rewards.' },
            { description: 'Restaurant Participation: Encouraging local restaurants to join the platform required demonstrating clear value.' },
            { description: 'Data Management: Managing user data securely while ensuring compliance with privacy regulations posed significant challenges.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/inKind.webp',
        solution: 'Developed a unified rewards platform, a user-friendly app interface, monthly bonuses for users, and a restaurant partnership program to highlight benefits.',
        techStacks: ['Mobile App Development', 'User Interface Design', 'Data Management', 'Partnership Marketing'],
    },
    {
        id: 'verizon',
        title: 'Verizon - Enhancing HR Management Automation',
        description: 'Verizon has integrated Robotic Process Automation (RPA) into its HR operations to streamline processes like payroll and employee onboarding, improving efficiency and reducing costs.',
        challenges: [
            { description: 'High Error Rates: Manual data entry led to frequent errors impacting payroll accuracy.' },
            { description: 'Time-Consuming Processes: Routine tasks consumed valuable time, preventing strategic engagement.' },
            { description: 'Data Management Issues: Managing large volumes of employee data created inefficiencies.' },
            { description: 'Scalability Concerns: Manual processes struggled to keep pace with growth.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/verizon.webp',
        solution: 'Implemented a comprehensive RPA strategy including process assessment, tool selection, pilot implementation, training, and a continuous improvement framework.',
        techStacks: ['Robotic Process Automation', 'Data Management', 'Process Automation Tools'],
    },
    {
        id: 'abpnews',
        title: 'ABP News - Enhancing User Engagement with New Application Features',
        description: 'ABP News partnered with Webkorps to develop new features for its mobile application, focusing on enhancing user engagement and improving the overall experience.',
        challenges: [
            { description: 'Limited User Interaction: The existing application lacked engaging features.' },
            { description: 'Inefficient Notification System: Users missed important updates due to a lack of personalized notifications.' },
            { description: 'Content Discovery Issues: Users struggled to find relevant articles and videos.' },
            { description: 'User Feedback Mechanism: The absence of feedback limited understanding of user preferences.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ABP.webp',
        solution: 'Developed interactive features, a personalized notification system, improved content discovery algorithms, and a user feedback mechanism within the app.',
        techStacks: ['Mobile App Development', 'User Engagement', 'Data Analytics'],
    },
    {
        id: 'wendy',
        title: 'Wendy\'s - Enhancing Customer Experience with New Features',
        description: 'Wendy\'s introduced innovative features to enhance customer experience and operational efficiency, including new restaurant designs and dynamic pricing strategies.',
        challenges: [
            { description: 'Increased Competition: The fast-food industry required differentiation through superior service.' },
            { description: 'Operational Inefficiencies: Traditional layouts led to longer wait times.' },
            { description: 'Customer Engagement: More interactive features were needed to enhance engagement.' },
            { description: 'Dynamic Pricing Challenges: Implementing a dynamic pricing model required careful management.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/wendy.webp',
        solution: ` Global Next Gen Restaurant Design: Introduced a modern design standard featuring dedicated
mobile order parking, in-restaurant pick-up shelving, and a new delivery pick-up window. This design
enhances efficiency by improving kitchen capacity and reducing wait times for customers
 Dynamic. Pricing Implementation: Rolled out digital menu boards that allow for fluctuating prices
based on demand, similar to ride-sharing models. This feature aims to optimize revenue while
providing customers with promotions during off-peak hours. Enhanced Digital Ordering Features: Integrated self-order kiosks and mobile app enhancements
that streamline the ordering process, making it easier for customers to place orders quickly and
efficiently. Customer Engagement Initiatives: Launched marketing campaigns to educate customers about
new features, particularly the benefits of dynamic pricing, ensuring transparency in how prices are
determined.`,
        techStacks: ['Restaurant Management', 'Dynamic Pricing', 'Digital Ordering Systems'],
    },
    {
        id: 'ethememe',
        title: 'Ethmeme - Simplifying Cryptocurrency Token Creation',
        description: 'Ethmeme.fun is a platform designed to simplify the process of creating and trading cryptocurrency tokens, allowing users to create tokens with no platform fees.',
        challenges: [
            { description: 'High Barriers to Entry: Existing platforms charged significant fees for token creation.' },
            { description: 'Complex Processes: Traditional token creation processes were cumbersome.' },
            { description: 'Lack of Transparency: Users faced uncertainty regarding fees and processes.' },
            { description: 'Limited Access to Resources: Newcomers struggled to find reliable resources for launching tokens.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ETHmeme.webp',
        solution: 'Developed a one-click token creation feature, established a transparent fee structure, implemented robust security protocols, and ensured seamless blockchain integration.',
        techStacks: ['Blockchain Development', 'Smart Contracts', 'Cryptocurrency Management'],
    },
    {
        id: 'wazir',
        title: 'Wazir - Enhancing a Crypto Trading App with Maintenance and Feature Development',
        description: 'Wazir is a leading cryptocurrency trading application that enables users to buy, sell, and trade various cryptocurrencies. This partnership with Webkorps focuses on enhancing the app\'s performance, security, and user engagement.',
        challenges: [
            { description: 'System Downtime: Frequent maintenance periods led to user frustration.' },
            { description: 'Scalability Issues: Existing infrastructure struggled with high transaction volumes.' },
            { description: 'Security Vulnerabilities: Continuous enhancements to security protocols were essential.' },
            { description: 'Feature Requests: Users expressed the need for advanced trading tools and better user interfaces.' }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/wezirx.webp',
        solution: 'Implemented scheduled maintenance protocols, migrated to a scalable cloud-based architecture, enhanced security measures, created a feature development roadmap, and improved user interface components.',
        techStacks: ['Cloud Infrastructure', 'Security Protocols', 'User Experience Design', 'Feature Development'],
    },
    {
        id: "purvankara",
        title: "Purvankara - Streamlining Email Communications with Scalable Solutions",
        description: "Purvankara Limited is a leading real estate company in India, recognized for its innovative and high-quality housing projects. The partnership with Webkorps aimed to tackle challenges in managing large-scale email communications, ensuring seamless integration, scalability, and enhanced user engagement.",
        challenges: [
            { "description": "Email Service Provider: Identifying a platform that could support high volumes, large attachments, and both API-based and SMTP services." },
            { "description": "High Volumes of Emails: Managing large-scale daily emails without interruptions or limitations." },
            { "description": "Integration with Existing Systems: Ensuring seamless integration with applications like SAP and Salesforce." },
            { "description": "Email Deliverability: Maintaining high deliverability rates and avoiding spam filters." },
            { "description": "Cost and Scalability: Balancing cost-efficiency while scaling for future growth." }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/puravankara.webp',
        solution: "Webkorps implemented a scalable solution that integrated a high-capacity email service provider supporting both API-based and SMTP emails. This allowed Purvankara to automate communications and generate dynamic PDFs within Salesforce. The solution ensured high deliverability rates, security compliance, and cost-effective scalability.",
        techStacks: ["API-based Email Services", "SMTP", "Salesforce Integration", "Dynamic PDF Generation", "Scalability Solutions"]
    },
    {
        id: "propertyfinder",
        title: "Property Finder - Revolutionizing Buyer-Centric Property Search",
        description: "Property Finder is a real estate platform designed to bridge the gap between buyers and the real estate market, offering a buyer-first approach to property transactions. The platform simplifies the process of finding and acquiring properties by understanding client needs and leveraging advanced technology for a streamlined property search experience.",
        challenges: [
            { "description": "Market Fragmentation: Difficulty in accessing all available property listings due to the fragmented nature of the real estate market." },
            { "description": "Buyer Representation: Ensuring unbiased advice and representation for buyers in a market dominated by agents working for sellers." },
            { "description": "Data Management: Managing large volumes of property data while ensuring accuracy and regulatory compliance." },
            { "description": "User Engagement: Encouraging active buyer participation and keeping them informed and engaged." }
        ],
        image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/propertyFinder.webp',
        solution: "Webkorps developed a centralized platform that aggregates property listings from multiple sources, prioritizing buyer interests with personalized property recommendations. Comprehensive support services were added to guide buyers through every step of the transaction process. The platform also integrated data analytics tools to provide insights on market trends and property values, and implemented engagement strategies to keep users active and informed.",
        techStacks: ["Centralized Database", "Personalized Recommendations", "Data Analytics", "Buyer Support Services", "User Engagement Strategies"]
    }
];


export default projectsData;