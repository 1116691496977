import React from 'react';


const Technologies = () => {
    const techStack = [
        { name: 'Ruby', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Ruby.svg" },
        { name: 'Angular', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Angular.svg" },
        { name: 'React', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/React.svg" },
        { name: 'VneJs', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Vue.js.svg" },
        { name: 'Ruby & Rails', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Ruby-on-Rails.svg" },
        { name: 'JavaScript', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/JavaScript.svg" },

        { name: 'Java', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Java.svg" },
        { name: 'kotlin', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Kotlin.svg" },
        { name: 'PHP', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/PHP.svg" },
        { name: 'AWS', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/AWS.svg" },
        { name: 'Azure', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Azure.svg" },

        { name: 'kubernetes', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Kubernetes.svg" },
        { name: 'Docker', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Docker.svg" },
        { name: 'figma', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Figma.svg" },
        { name: 'flutter', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Flutter.svg" },
        { name: 'Iconic', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Ionic.svg" },
        { name: 'Python', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Python.svg" },
        { name: 'Swift', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/Swift.svg" },
    ];

    return (
        <div className="flex flex-col items-center justify-center   mt-14 xl:mt-14 ipad-pro:mt-10 md:mt-10">
            <div className="flex flex-col w-full  xl:flex-row ipad-pro:flex-col  items-center md:items-start mb-6 md:mb-8 px-[7%] md:px-[7%] xl:px-[160px] ipad-pro:px-[100px] space-y-4 md:space-y-0 md:gap-8 space-x-1 lg:gap-[40px] md:space-x-1">
                <h1 className="text-[32px] md:text-[36px] lg:text-[48px] text-center md:text-left font-bold   leading-[1.4] ">
                    <span className='font-bold'>Technologies</span><br className='md:hidden block ipad-pro:hidden xl:block ' />
                    <span className="md:whitespace-nowrap md:ml-3 ml-0 ipad-pro:ml-3 xl:ml-0">
                        We Are Expert at<span style={{ color: '#1887C9' }}>.</span> 
                    </span></h1>
                <div>
                    <p className='pb-3 text-center md:text-left'><b >Discover the Power Behind Our Digital Solutions.</b><br /></p>
                    <p className="text-lg text-[#000000] leading-relaxed text-center md:text-left pr-0 md">
                        We’re passionate about technology that delivers results. By harnessing cutting-edge tools and frameworks, we transform complex challenges into streamlined solutions. Our tech stack isn’t just about using the latest trends—it’s about choosing the right technologies to bring your vision to life.
                    </p>
                </div>


            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-6 ipad-pro:grid-cols-3  gap-x-32 gap-y-16 justify-items-center  px-[14%] flex  w-full">
                {techStack.map((tech, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <img src={tech.logo} alt={`${tech.name} logo`} className="w-[80px] h-[80px] lg:w-[80px] lg:h-[80px] md:w-[850px] md:h-[80px] m-2" />
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Technologies;
