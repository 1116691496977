import './App.css';
import './index.css';
import React, { useEffect } from 'react';
import Home from './components/sharedComponents/Home';
import AboutUs from './components/subComponents/aboutUs';
import CaseStudy from './components/CaseStudy';
import JoinUs from './components/subComponents/joinUs';
import AboutContainer from './components/About/AboutContainer';
import { Routes, Route } from 'react-router-dom';
import Login from './components/hr-adminComponents/login';
import JobOpening from './components/hr-adminComponents/job-openings';
import JobOpeningList from './components/hr-adminComponents/job-opening-list';
import JoinUsDetails from './components/subComponents/joinUsDetails';
import CaseStudyPages from './components/CaseStudy/CaseStudyPages';
import Services from './components/subComponents/services';
import AppServices from './components/Services/AppServices';
import WebService from './components/Services/WebServices';
import TopServices from './components/Services/TopServices';
import DigitalServices from './components/Services/DigitalServices';
import ITServices from './components/Services/ItServices';
import CloudServices from './components/Services/CloudServices';
import ScrollToTop from './components/sharedComponents/ScrollToTop';

function App() {
  return (
    <Routes>
      <Route path="/" element={<><ScrollToTop /><Home /></>} />
      <Route path="/about-us" element={<><ScrollToTop /><AboutContainer /></>} />
      <Route path="/login" element={<><ScrollToTop /><Login /></>} />
      <Route path="/job-openings/:id" element={ <><ScrollToTop /><JobOpening /></>} />
      <Route path="/job-openings" element={ <><ScrollToTop /><JobOpeningList /></>} />
      <Route path="/join-us/:id" element={ <><ScrollToTop /> <JoinUsDetails /></>} />
      <Route path="/case-study" element={<><ScrollToTop /><CaseStudy /></>} />
      <Route path="/case-study/:id" element={<><ScrollToTop /><CaseStudyPages /></>} />
      <Route path="/our_services" element={<><ScrollToTop /><Services /></>} />
      <Route path="/mobile-app-development" element={<><ScrollToTop /> <AppServices /></>} />
      <Route path="/web-development" element={ <><ScrollToTop /> <WebService /></>} />
      <Route path="/top_services" element={ <><ScrollToTop /><TopServices /></>} />
      <Route path="/digital-transformation" element={ <><ScrollToTop /> <DigitalServices /></>} />
      <Route path="/IT_services" element={ <><ScrollToTop /> <ITServices /></>} />
      <Route path="/cloud-services" element={ <><ScrollToTop /> <CloudServices /></>} />
      <Route path="/join-us" element={<><ScrollToTop /><JoinUs /></>} />
      <Route path="/contact" element={<><ScrollToTop /><AboutUs /></>} />
    </Routes>
  );
}

export default App;
