import React, { useState, useEffect} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import "react-quill/dist/quill.snow.css";
import Header from "../sharedComponents/Header";
import Footer from '../sharedComponents/footer';
import { Link } from 'react-router-dom';
const ReactQuill = React.lazy(() => import('react-quill'));

export default function JobOpening() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        job_title: '',
        job_type: '',
        experience: '',
        deadline: '',
        location: '',
        working_days: '',
        designation: '',
        shift_start_time: '',
        shift_end_time: '',
        number_of_vacancy: '',
        skills_requirements: '',
        educational_requirements: '',
        additional_skills: ''
    });

    const [errors, setErrors] = useState({});
    useEffect(() => {
      if (id) {
        fetch(`https://be.webkorps.com:4202/v1/career_jobs/${id}`)
          .then(response => response.json())
          .then(data => {
            if (data) {
              setFormData({
                job_title: data.job_title || '',
                job_type: data.job_type || '',
                experience: data.experience || '',
                deadline: data.deadline || '',
                location: data.location || '',
                working_days: data.working_days || '',
                additional_skills: data.additional_skills || '',
                designation: data.designation || '',
                educational_requirements: data.educational_requirements || '',
                shift_start_time: data.shift_start_time || '',
                shift_end_time: data.shift_end_time || '',
                number_of_vacancy: data.number_of_vacancy || '',
                skills_requirements: data.skills_requirements || '',
              });
            }
          })
          .catch(error => console.error('Error fetching job data:', error));
      }
    }, [id]);
    const fieldsConfig = [
        { name: 'job_title', label: 'Job Title', type: 'text' },
        { name: 'designation', label: 'Designation', type: 'text' },
        { name: 'job_type', label: 'Job Type', type: 'text' },
        { name: 'experience', label: 'Experience', type: 'text' },
        { name: 'deadline', label: 'Deadline', type: 'number' },
        { name: 'location', label: 'Location', type: 'text' },
        { name: 'working_days', label: 'Working Days', type: 'number' },
        { name: 'number_of_vacancy', label: 'No. of Vacancy', type: 'number' },
        { name: 'shift_start_time', label: 'Shift Start Time', type: 'time' },
        { name: 'shift_end_time', label: 'Shift End Time', type: 'time' },
        { name: 'skills_requirements', label: 'Who Are We Looking For', type: 'quill' },
        { name: 'educational_requirements', label: 'Educational Requirement', type: 'quill' },
        { name: 'additional_skills', label: 'Bonus Points for Familiarity with', type: 'quill' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleQuillChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        fieldsConfig.forEach(field => {
            if (!formData[field.name]) {
                newErrors[field.name] = `${field.label} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const isCreatePath = location.pathname.endsWith('/create');
        try {
            const method = isCreatePath || !id ? 'POST' : 'PUT';
            const url = !isCreatePath && id
                ? `https://be.webkorps.com:4202/v1/career_jobs/${id}`
                : 'https://be.webkorps.com:4202/v1/career_jobs';

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                navigate('/job-openings');
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Header />
            <div className="flex justify-center p-32">
  <div className="max-w-screen-md mx-auto p-5">
    <div className="text-center mb-16">
      <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
        Job Opening Application
      </h3>
    </div>

    <form className="w-full flex flex-wrap -mx-3 mb-6">
      <div className="flex flex-wrap -mx-3 mb-6">
        {fieldsConfig.map((field, index) => (
          <div
          key={field.name}
          className={field.type === 'quill' ? 'w-full px-3 mb-6' : 'w-full md:w-1/3 px-3 mb-6'}
        > <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {field.label}
      </label>  
       {field.type === 'quill' ? (
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                value={formData[field.name]}
                onChange={(value) => handleQuillChange(field.name, value)}
              />
            ) : (
              <div className="m-2">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                />
              </div>
            )}
            {errors[field.name] && <span>{errors[field.name]}</span>}
          </div>
        ))}
      </div>

      <div className="flex justify-start w-full mt-4">
      <Link to="/job-openings" > <button
              class="shadow primary-color hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded mr-4"
              type="submit"
              
            >
              Back
            </button></Link>
        <button
          className="shadow primary-color hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>

            <Footer />
        </>
    );
}