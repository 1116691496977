const s3BaseUrl = 'https://webkorps-app.s3.us-east-1.amazonaws.com/';

const ClientsImg = [
  {
    id: 1,
    src: `${s3BaseUrl}abpClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 2,
    src: `${s3BaseUrl}acimaClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 3,
    src: `${s3BaseUrl}CignaClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 4,
    src: `${s3BaseUrl}cryoportClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 5,
    src: `${s3BaseUrl}digikagazclient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 6,
    src: `${s3BaseUrl}ethmemeClient.svg`,
    alt: "Image 3 description",
  },
  {
    id: 7,
    src: `${s3BaseUrl}projectImage1.webp`,
    alt: "Image 3 description",
  },
  {
    id: 8,
    src: `${s3BaseUrl}propertyFinderC.webp`,
    alt: "Image 3 description",
  },
  {
    id: 9,
    src: `${s3BaseUrl}purvaClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 10,
    src: `${s3BaseUrl}apclient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 11,
    src: `${s3BaseUrl}SonicClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 12,
    src: `${s3BaseUrl}cloudshimClient.svg`,
    alt: "Image 3 description",
  },
  {
    id: 13,
    src: `${s3BaseUrl}Frame4.svg`,
    alt: "Image 3 description",
  },
];

const ClientsImg2 = [
  {
    id: 1,
    src: `${s3BaseUrl}bhaibandhu.webp`,
    alt: "Image 1 description",
  },
  {
    id: 2,
    src: `${s3BaseUrl}paypalClient.webp`,
    alt: "Image 2 description",
  },
  {
    id: 3,
    src: `${s3BaseUrl}tonikClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 4,
    src: `${s3BaseUrl}verizonClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 5,
    src: `${s3BaseUrl}medhostClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 6,
    src: `${s3BaseUrl}owenscorningC.webp`,
    alt: "Image 3 description",
  },
  {
    id: 7,
    src: `${s3BaseUrl}scoutrClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 8,
    src: `${s3BaseUrl}shreeLaksmiClient.webp`,
    alt: "Image 3 description",
    fill: true,
  },
  {
    id: 9,
    src: `${s3BaseUrl}stylaClent.webp`,
    alt: "Image 3 description",
  },
  {
    id: 10,
    src: `${s3BaseUrl}pabbleClient.webp`,
    alt: "Image 3 description",
  },
  {
    id: 11,
    src: `${s3BaseUrl}tfsCleint.webp`,
    alt: "Image 3 description",
  },
  {
    id: 12,
    src: `${s3BaseUrl}inkindClent.webp`,
    alt: "Image 3 description",
  },
];

export { ClientsImg, ClientsImg2 };
