import React from "react";


const Card = ({ image, name, position, linkedIn }) => {
  return (
    <div className="relative w-[100%] md:w-[100%] xl:w-[100%] h-[444px]  xl:h-[444px] ipad-pro:h-[555px]   overflow-hidden bg-black group">
      {/* Image Section */}
      <div className="card-image-container relative w-full h-full transition-transform duration-300">
        <img
        
          src= {image}
          alt="card background"
          className="w-full h-full object-cover transition-transform duration-300"
        />
      </div>
      {/* Hover Effect for the Bottom */}
      <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white p-4 flex items-center justify-between opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-out shadow-[0px_0px_30px_10px_rgba(0,0,0,0.7)]">
        <div className="text-left">
          <p className="text-white font-quicksand font-bold text-[16px] leading-[20px]">{name}</p>
          <p className="text-white font-quicksand text-[14px] leading-[18px]">{position}</p>
        </div>
        <a href={linkedIn} target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-500">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 2.25C14.6478 2.25 15.0294 2.40804 15.3107 2.68934C15.592 2.97064 15.75 3.35218 15.75 3.75V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H14.25ZM13.875 13.875V9.9C13.875 9.25155 13.6174 8.62965 13.1589 8.17112C12.7003 7.7126 12.0785 7.455 11.43 7.455C10.7925 7.455 10.05 7.845 9.69 8.43V7.5975H7.5975V13.875H9.69V10.1775C9.69 9.6 10.155 9.1275 10.7325 9.1275C11.011 9.1275 11.278 9.23812 11.475 9.43504C11.6719 9.63195 11.7825 9.89902 11.7825 10.1775V13.875H13.875ZM5.16 6.42C5.49417 6.42 5.81466 6.28725 6.05095 6.05095C6.28725 5.81466 6.42 5.49417 6.42 5.16C6.42 4.4625 5.8575 3.8925 5.16 3.8925C4.82384 3.8925 4.50144 4.02604 4.26374 4.26374C4.02604 4.50144 3.8925 4.82384 3.8925 5.16C3.8925 5.8575 4.4625 6.42 5.16 6.42ZM6.2025 13.875V7.5975H4.125V13.875H6.2025Z" fill="white" />
          </svg>
        </a>
      </div>
    </div>
  );
};
const LeadersCardContainer = () => {
  const cards = [
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage1.webp", name: "Chirag Agrwal", position: "CEO & Founder", linkedIn: "https://www.linkedin.com/in/chirag-a-8a2a58a0/" },
    {image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage2.webp", name: "Amul Choudhary", position: "COO & Co-Founder", linkedIn: "https://www.linkedin.com/in/amul-c-044070a1/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage3.webp", name: "Vikas Dameriya", position: "Vice President of Business", linkedIn: "https://www.linkedin.com/in/vikas-dameriya-878526b1/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage4.webp", name: "Parakh Garg", position: "CTO", linkedIn: "https://www.linkedin.com/in/parakhgarg/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage5.webp", name: "Akash Chandrawade", position: "Principal Consultant", linkedIn: "https://www.linkedin.com/in/akash-chandrawade-67206ba1/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/Leaderimage6.webp", name: "Swapnil Bhosle", position: "Vice President of Engineering", linkedIn: "https://www.linkedin.com/in/swapnil-bhosale-7534b4138/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage7.webp", name: "Mehul Shah", position: "Head of Operations (Pune)", linkedIn: "https://www.linkedin.com/in/mehul-s-01837146/" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/LeaderImage8.webp", name: "Ajay Thakur", position: "Head Of Recruitment", linkedIn: "https://www.linkedin.com/in/ajay-thakur-535929226/" },
  ];
  return (
    <div className="content text-center items-center px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full   mt-0 md:mt-10 ">
      <p className="font-quicksand font-bold  md:text-left text-center text-[48px] leading-[67.2px] pb-6">
        Our Leaders<span style={{ color: '#1887C9' }}>.</span> 
      </p>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-4 ipad-pro:grid-cols-2    gap-10 w-[100%]">
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            name={card.name}
            position={card.position}
            linkedIn={card.linkedIn}
          />
        ))}
      </div>
    </div>
  );
};
export default LeadersCardContainer;