import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import { Helmet } from 'react-helmet-async';
import CircleLeft from './../../assets/images/CircleLeft.svg'


function AppServices() {
  const offers = [
    { title: 'iOS App<br/>  Development', details: 'We craft high-performance, user-friendly iOS apps tailored to your business needs. Our team ensures seamless functionality, sleek design, and superior user experiences.' },
    { title: 'UI/UX <br/> Design', details: 'Our expert designers craft intuitive, visually stunning interfaces, ensuring that your app not only looks great but also delivers an exceptional user experience.' },
    { title: 'Android App <br/> Development', details: 'We specialize in building custom Android apps that deliver smooth performance and robust features. Our solutions are designed to help your business thrive on the Android platform.' },
    { title: 'Custom Mobile <br/> Application', details: 'We develop tailor-made mobile applications that align perfectly with your unique business requirements, ensuring a personalized and scalable solution.' },
    { title: 'Cross-Platform Development', details: 'We create efficient cross-platform apps that work seamlessly on both iOS and Android, ensuring consistent performance and user experience across devices.' },
    { title: 'Mobile App <br/> Maintenance', details: 'Our dedicated support team provides continuous maintenance and updates, ensuring your app remains secure, up-to-date, and running smoothly over time.' },
  ];

  const reasons = [
    "Expertise in Mobile-First Design: At Webkorps, we understand that mobile apps are not just scaled-down versions of websites. Our team specializes in mobile-first design principles, ensuring that your app is built from the ground up to provide an exceptional user experience on small screens and touch interfaces.",
    "Agile and Collaborative Approach: We believe in an agile and collaborative approach to mobile app development. Our iterative process allows for continuous feedback, rapid adaptation, and seamless collaboration with your team, ensuring that your app remains aligned with your evolving requirements and market trends.",
    "Cross-Platform Proficiency: Whether you need a native app for iOS or Android, or a cross-platform solution that runs seamlessly across multiple devices and operating systems, our team has the expertise to deliver. We leverage cutting-edge technologies like React Native and Flutter to maximize your investment and reach a broader audience.",
    "Robust Testing and Quality Assurance: We understand that a successful mobile app requires more than just great design and development. Our rigorous testing and quality assurance processes ensure that your app is thoroughly evaluated for performance, usability, and compatibility across various devices, operating systems, and network conditions.",
    "Continuous Support and Maintenance: Our commitment to your success doesn't end with the initial launch. We provide ongoing support and maintenance services to keep your app up-to-date with the latest technologies, security patches, and platform updates, ensuring a seamless and secure experience for your users."
  ];

  return (
    <>
      <Helmet>
        <title>Best Mobile App Development Company | Custom App Solutions</title>
        <meta
          name="description"
          content="Build user-friendly mobile apps with our expert mobile app development services. We create custom app solutions tailored to your business needs for seamless performance and great user experiences."
        />
        <meta property="og:url" content="https://www.webkorps.com/mobile-app-development" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best Mobile App Development Company | Custom App Solutions" />
        <meta property="og:description" content="Build user-friendly mobile apps with our expert mobile app development services. We create custom app solutions tailored to your business needs for seamless performance and great user experiences." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Mobile+App+Development+Company.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/mobile-app-development" />
        <meta name="twitter:title" content="Best Mobile App Development Company | Custom App Solutions" />
        <meta name="twitter:description" content="Build user-friendly mobile apps with our expert mobile app development services. We create custom app solutions tailored to your business needs for seamless performance and great user experiences." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Mobile+App+Development+Company.webp" />
        <link rel="canonical" href="https://www.webkorps.com/mobile-app-development" />
      </Helmet>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] md:leading-[46.2px] font-bold  w-[100%]">Mobile App Development</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
              Unlock the Power of Mobility
            </h2>
            <p className="text-[#072125] mt-3  xl:w-[670px] ipad-pro:w-[350px]">
              In today's fast-paced digital landscape, mobile applications have become an indispensable tool for businesses to connect with their customers. At Webkorps, we understand the critical role mobile apps play in driving engagement, boosting revenue, and staying ahead of the competition. That's why we offer cutting-edge mobile development services tailored to your unique needs.            </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img src="https://webkorps-app.s3.us-east-1.amazonaws.com/appServices.webp" alt="Web Solutions Image" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:mx-[203px] ipad-pro:mx-[100px] mt-7   w-full mb-6'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:px-[203px] ipad-pro:px-[85px] px-[4%] mb-5">
          {offers.map((offer, index) => (
            <div key={offer.id} className="w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%]  p-5">
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
        </div>




        {/* Why Choose Section */}
        <div className="bg-gray-100 pt-10 px-[7%] xl:px-[160px] ipad-pro:px-[100px] rounded-lg shadow-md relative">
          <div>
            <h3 className="text-2xl font-bold text-center mb-0 md:mb-4">
              Why Choose Webkorps for Your App Solutions?
            </h3>
            <div className="content relative pl-[11%] md:pl-0 w-full text-center flex justify-start md:text-left">
              {/* Reasons List */}
              <div className="absolute top-0 xl:pl-[160px] ipad-pro:px-[100px] left-1/2 transform -translate-x-1/2 -translate-y-[0%] w-full ">
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-x-6 text-gray-700">
                  {reasons.map((reason, index) => {
                    const [title, description] = reason.split(': ');

                    return (
                      <li key={index} className="flex mt-5">
                        <b className="text-2xl font-bold">{(index + 1).toString().padStart(2, '0')}</b>
                        <p className="ml-2 mt-1">
                          <strong className="mb-2 block">{title}</strong>
                          {description}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Image */}
              <img
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/CircleLeft.svg'}
                alt="MVP to Product Image"
                className="w-[40%] h-[700px] pt-[0%]"
              />
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default AppServices;
