import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import CircleLeft from './../../assets/images/CircleLeft.svg'


function TopServices() {
  const location = useLocation();

  const offers = [
    { title: 'Blockchain <br/> Integration', details: 'We integrate blockchain technology into your systems, enhancing security, transparency, and traceability for transactions and data management.' },
    { title: 'Robotic Process <br/>Automation(RPA)', details: 'We deploy RPA solutions to automate repetitive tasks, improving efficiency and accuracy while allowing your team to focus on strategic initiatives.' },
    { title: 'IoT <br/>Integration', details: 'Our team connects and integrates IoT devices, enabling seamless data exchange and automation, driving efficiency and smarter decision-making.' },
    { title: 'Salesforce <br/> Integration', details: 'We integrate Salesforce with your existing applications, streamlining processes and ensuring a unified view of customer interactions for improved sales and service.' },
    { title: 'Data Analytics<br/> Integration', details: 'We implement data analytics solutions that integrate with your existing systems, providing actionable insights and enabling data-driven decision-making.' },
    { title: 'SAP<br/> Integration', details: 'Our team specializes in integrating SAP systems with other enterprise applications, ensuring smooth data flow and enhancing operational efficiency across your organization.' },
  ];
  const reasons = [
    "Extensive Integration Expertise: At Webkorps, we excel in integrating a wide variety of systems and platforms, ensuring smooth and efficient workflows. Our team has experience with everything from CRM and ERP systems to custom APIs, guaranteeing that your business operations are fully aligned.",
    "Seamless and Customized Solutions: We understand that every business has unique needs. Webkorps tailors each integration to fit your specific requirements, ensuring that your systems work together effortlessly, with minimal disruption to your operations.",
    "Enhanced Efficiency and Automation: Our integrations help eliminate manual tasks and streamline data flow between different platforms. This not only boosts productivity but also enhances accuracy and decision-making by ensuring real-time access to critical information.",
    "Security and Compliance Focus: Security is a top priority in all of our integration services. Webkorps ensures that your data is protected and that integrations meet all necessary compliance standards, providing peace of mind while enhancing your system’s capabilities.",
    "Continuous Support and Future-Ready Integrations: We provide ongoing support and monitoring to ensure your integrations remain smooth and up-to-date. Webkorps ensures that your systems are prepared for future growth, allowing you to easily scale and adapt as your business evolves."
  ];

  const splitIndex = Math.ceil(reasons.length / 2);
  const firstHalf = reasons.slice(0, splitIndex);
  const secondHalf = reasons.slice(splitIndex);
  return (
    <>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
      <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
      <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] md:leading-[46.2px] font-bold  w-[100%]">Top Integrations</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
            Supercharge Your Systems with Our Top Integrations.
            </h2>
            <p className="text-[#072125] mt-3  xl:w-[670px] ipad-pro:w-[350px]">
            Our top integrations streamline your operations by seamlessly connecting the tools and platforms you rely on. We provide expert integration services that enhance efficiency, improve data flow, and reduce manual work, ensuring all your systems work in harmony. Whether you need CRM, ERP, cloud, or custom software integrations, our solutions are tailored to your business needs, helping you unlock new capabilities and optimize workflows for better performance and growth.        </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img src="https://webkorps-app.s3.us-east-1.amazonaws.com/top_intregrition.webp" alt="Web Solutions Image" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:mx-[203px] ipad-pro:mx-[100px] mt-7   w-full mb-6'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:px-[203px] ipad-pro:px-[85px] px-[4%] mb-5">
          {offers.map((offer, index) => (
            <div key={offer.id} className="w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%]  p-5">
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
        </div>




        {/* Why Choose Section */}
        <div className="bg-gray-100  pt-10 px-[7%] xl:px-[160px] ipad-pro:px-[100px] rounded-lg shadow-md">
          <div className="">
            <h3 className="text-2xl font-bold text-center mb-0 md:mb-4 ">Why Choose Webkorps for Your Top Integrations?</h3>
            <div className="content relative pl-[11%] md:pl-0 w-full text-center flex justify-start  md:text-left">
              {/* Reasons List */}
              <div className="absolute top-0 xl:pl-[160px] ipad-pro:pl-[100px] left-1/2 transform -translate-x-1/2 -translate-y-[0%] w-full ">
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-x-6 text-gray-700">
                  {reasons.map((reason, index) => {
                    const [title, description] = reason.split(': ');

                    return (
                      <li key={index} className="flex mt-5">
                        <b className="text-2xl font-bold">{(index + 1).toString().padStart(2, '0')}</b>
                        <p className="ml-2 mt-1">
                          <strong className="mb-2 block">{title}</strong>
                          {description}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Image */}
              <img
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/CircleLeft.svg'}
                alt="MVP to Product Image"
                className=" w-[40%] h-[700px] pt-[0%] "
              />
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TopServices;
