import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const services = [
    { name: "Application Development", path: "/mobile-app-development" },
    { name: "Web Solutions", path: "/web-development" },
    { name: "Digital Transformation", path: "/digital-transformation" },
    { name: "Cloud Solutions", path: "/cloud-services" },
    { name: "Manage IT Solutions", path: "/IT_services" },
    { name: "MVP to Product", path: "/our_services" },
    { name: "Top Integrations", path: "/top_services" },
];


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isMobileServicesDropdownOpen, setIsMobileServicesDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const servicesRef = useRef(null);

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const location = useLocation();
const currentPath = location.pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target) &&
        servicesRef.current && !servicesRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
        setIsServicesDropdownOpen(false);
        setIsMobileServicesDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
<header className="content w-full h-[112px] px-[7%] sm:px-[40px] md:px-[20px] xl:px-[160px] ipad-pro:px-[100px] pt-5 pb-5 flex items-center justify-between z-10">
      <div className="flex items-center space-x-4 ">
        <Link to="/"><img  src="https://webkorps-app.s3.us-east-1.amazonaws.com/logo.webp"  alt="Company Logo" className="h-16" />
        </Link>
        <span
          className="text-[33px] font-bold block    leading-[46px]"
          style={{ color: "#1887C9" }}
        >
          Webkorps
        </span>
      </div>

      {/* Center: Navigation Links */}
      <div className="flex-grow hidden md:flex justify-evenly space-x-8 sm:space-x-2 md:space-x-2   ipad-pro:space-x-2 ml-[150px]  sm:ml-[30px] md:ml-[30px] ipad-pro:ml-20">
  <Link
    to="/about-us"
    className={`text-lg ${currentPath === "/about-us" && !isServicesDropdownOpen ? "text-[#64AFDB] border-b-2 border-[#64AFDB]" : ""}`}
  >
    About
  </Link>

  {/* Services with dropdown */}
  <div className="relative" ref={servicesRef}>
  <Link
    className={`text-lg ${
      isServicesDropdownOpen ? "text-[#64AFDB] border-b-2 border-[#64AFDB]" : ""
    }`}
    onClick={() => setIsServicesDropdownOpen(!isServicesDropdownOpen)}
    onMouseEnter={() => setIsServicesDropdownOpen(!isServicesDropdownOpen)}
  >
    Services
  </Link>
  {/* Dropdown for Desktop */}
  {isServicesDropdownOpen && (
    <div
      className="absolute left-0 mt-2 w-56 bg-white border border-gray-300 shadow-lg z-50"  // z-index added here
      style={{ minWidth: "200px", padding: "8px" }}  // Adjusts width and padding for better fit
      onMouseEnter={() => setIsServicesDropdownOpen(true)}
      onMouseLeave={() => setIsServicesDropdownOpen(false)}
    >
      {services.map((service) => (
        <Link
          key={service.name}
          to={service.path}
          className="block px-4 py-2 text-gray-700 hover:bg-gray-200"
        >
          {service.name}
        </Link>
      ))}
    </div>
  )}
</div>


  <Link
    to="/case-study"
    className={`text-lg ${currentPath === "/case-study" && !isServicesDropdownOpen  ? "text-[#64AFDB] border-b-2 border-[#64AFDB]" : ""}`}
  >
    Case Studies
  </Link>
  <Link
    to="/join-us"
    className={`text-lg ${currentPath === "/join-us" && !isServicesDropdownOpen  ? "text-[#64AFDB] border-b-2 border-[#64AFDB]" : ""}`}
  >
    Careers
  </Link>
</div>

      <div className="hidden md:block font-bold ">
        <Link to="/contact"
          className="px-4 py-2 text-white rounded hover:bg-blue-600 hover:border-b-2 hover:border-blue-500"
          style={{ backgroundColor: "#1887C9" }}
        >
          Talk to Us
        </Link>
      </div>

      {/* Toggle Button for Mobile View */}
      <button
        ref={buttonRef}
        className="md:hidden flex items-center"
        onClick={handleToggle}
        aria-label="Toggle navigation"
      >
        <svg
          className="w-6 h-6 text-[#1887C9]"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>

      {/* Mobile Navigation Menu */}
      <div
        ref={menuRef}
        className={`md:hidden lg:hidden absolute top-16 z-50 right-0 w-48 bg-white border border-gray-300 shadow-lg ${isMenuOpen ? 'block' : 'hidden'}`}
      >
        <Link to="/about-us" className="block px-4 py-2 text-lg hover:bg-gray-200">
          About
        </Link>

        {/* Mobile Services with dropdown */}
        <div className="relative">
          <button
            className="block px-4 py-2 text-lg hover:bg-gray-200 w-full text-left"
            onClick={() => setIsMobileServicesDropdownOpen(!isMobileServicesDropdownOpen)}
          >
            Services
            <svg
              className={`w-4 h-4 inline-block ml-2 transform ${isMobileServicesDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Dropdown for Mobile */}
          {isMobileServicesDropdownOpen && (
            <div className="bg-white border-t border-gray-300">
              {services.map((service) => (
                <Link
                  key={service.name}
                  to={service.path}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-200"
                >
                  {service.name}
                </Link>
              ))}
            </div>
          )}
        </div>

        <Link to="/case-study" className="block px-4 py-2 text-lg hover:bg-gray-200">
          Case Studies
        </Link>
        <Link to="/join-us" className="block px-4 py-2 text-lg hover:bg-gray-200">
          Careers
        </Link>
        <Link to="/contact"
          className="block px-4 py-2 text-white rounded hover:bg-blue-600"
          style={{ backgroundColor: "#1887C9" }}
        >
          Talk to Us
        </Link>
      </div>
    </header>
  );
};

export default Header;
