import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import { Helmet } from 'react-helmet-async';
import CircleLeft from './../../assets/images/CircleLeft.svg'


function WebService() {
  const location = useLocation();

  const offers = [
    { title: 'Front-End <br/> Development', details: 'Captivating and intuitive user interfaces combine visual appeal with easy navigation, enhancing user satisfaction. By focusing on seamless interactions, these designs boost engagement and create a smooth user experience.' },
    { title: 'Content Management System (CMS)', details: 'We create custom CMS solutions tailored to your needs, offering an intuitive interface and powerful backend for seamless content management and easy website updates.' },
    { title: 'Back-End <br/> Development', details: 'Building robust and efficient backend systems ensures smooth performance and scalability. By focusing on data handling, security, and seamless integration, the backend creates a stable foundation that enhances the overall user experience.' },
    { title: 'Web <br/> Applications', details: 'We develop custom web applications tailored to your business needs, offering intuitive user interfaces and powerful backends for seamless functionality and performance across all devices.' },
    { title: 'Full-Stack <br/>Development', details: 'Full stack development combines both frontend and backend expertise to deliver a complete, seamless user experience. By integrating intuitive interfaces with robust backend systems, it ensures efficient performance, scalability, and a cohesive flow between user interactions and data management.' },
    { title: 'E-Commerce <br/>Services', details: 'We provide custom e-commerce solutions designed to fit your business, delivering user-friendly interfaces and secure backends for seamless shopping experiences and smooth transactions.' },
  ];

  const reasons = [
    "User-Centric Design: Our web design philosophy centers around creating exceptional user experiences. We prioritize intuitive navigation, compelling visuals, and seamless functionality to captivate and engage your audience.",
    "Agile Development Methodology: We embrace an agile development approach, fostering continuous collaboration, iteration, and rapid adaptation to ensure your web solutions align with your evolving needs and market trends.",
    "Cross-Browser Compatibility: Our web solutions are meticulously tested for compatibility across various browsers and devices, ensuring a consistent and reliable experience for your users, regardless of their preferred platform.",
    "Performance Optimization: We understand the importance of fast load times and smooth performance for a positive user experience. Our web solutions are optimized for speed and efficiency, ensuring your visitors enjoy a seamless browsing experience.",
    "Ongoing Support and Maintenance: Our commitment extends beyond the initial launch. We provide comprehensive support and maintenance services to keep your web solutions up-to-date, secure, and performing at their best."
  ];

  const splitIndex = Math.ceil(reasons.length / 2);
  const firstHalf = reasons.slice(0, splitIndex);
  const secondHalf = reasons.slice(splitIndex);
  return (
    <>
      <Helmet>
        <title>Leading Web Development Company | Custom Web Solutions</title>
        <meta
          name="description"
          content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals."
        />
        <meta property="og:url" content="https://www.webkorps.com/web-development" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading Web Development Company | Custom Web Solutions" />
        <meta property="og:description" content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Web+Development+Company.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/web-development" />
        <meta name="twitter:title" content="Leading Web Development Company | Custom Web Solutions" />
        <meta name="twitter:description" content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Web+Development+Company.webp" />
        <link rel="canonical" href="https://www.webkorps.com/web-development" />

      </Helmet>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] md:leading-[46.2px] font-bold  w-[100%]">Web Solutions</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
              Elevate Your Online Presence
            </h2>
            <p className="text-[#072125] mt-3  xl:w-[670px] ipad-pro:w-[350px]">
              In the digital age, having a strong online presence is essential for businesses to thrive. At Webkorps, we understand the importance of creating a seamless and engaging web experience that resonates with your audience and drives conversions. Our cutting-edge web solutions are tailored to meet your unique needs and propel your online success.           </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img src="https://webkorps-app.s3.us-east-1.amazonaws.com/webservices.webp" alt="Web Solutions Image" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:mx-[203px] ipad-pro:mx-[100px] mt-7   w-full mb-6'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:px-[203px] ipad-pro:px-[85px] px-[4%] mb-5">
          {offers.map((offer, index) => (
            <div key={offer.id} className="w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%]  p-5">
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
        </div>




        {/* Why Choose Section */}
        <div className="bg-gray-100  pt-10 px-[7%] xl:px-[160px] ipad-pro:px-[100px] rounded-lg shadow-md">
          <div className="">
            <h3 className="text-2xl font-bold text-center mb-0 md:mb-4 ">Why Choose Webkorps for Your Web Solutions?</h3>
            <div className="content relative pl-[11%] md:pl-0 w-full text-center flex justify-start  md:text-left">
              {/* Reasons List */}
              <div className="absolute top-0 xl:pl-[160px] ipad-pro:pl-[100px] left-1/2 transform -translate-x-1/2 -translate-y-[0%] w-full ">
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-x-6 text-gray-700">
                  {reasons.map((reason, index) => {
                    const [title, description] = reason.split(': ');

                    return (
                      <li key={index} className="flex mt-5">
                        <b className="text-2xl font-bold">{(index + 1).toString().padStart(2, '0')}</b>
                        <p className="ml-2 mt-1">
                          <strong className="mb-2 block">{title}</strong>
                          {description}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Image */}
              <img
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/CircleLeft.svg'}
                alt="MVP to Product Image"
                className=" w-[40%] h-[700px] pt-[0%] "
              />
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WebService;
