import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import Header from "../sharedComponents/Header";
import Footer from "../sharedComponents/footer";

export default function JoinUsDetails() {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetch(`https://be.webkorps.com:4202/v1/career_jobs/4`)
        .then(response => response.json())
        .then(data => {
          if (data) {
            setJobDetails(data);
          }
        })
        .catch(error => console.error('Error fetching job data:', error))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  const items = [
    { name: 'Location', key: 'location', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/locationIcon.webp' },
    { name: 'Job Type', key: 'job_type', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/jobTypeIcon.webp' },
    { name: 'Date posted', key: 'created_at', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/calendarIcon.webp' },
    { name: 'Experience', key: 'experience', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ExperienceIcon.webp' },
    { name: 'Working Hours', key: 'shift_start_time', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/Hoursicon.webp' },
    { name: 'Working Days', key: 'working_days', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/WorkingIcon.webp' },
    { name: 'Vacancy', key: 'number_of_vacancy', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/vacancyIcon.webp' },
  ];

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    reference_name: '',
    gender: '',
    job_location: '',
    date_of_availability: ''
  });

  const fieldsConfig = [
    { name: 'full_name', label: 'Full Name', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'phone_number', label: 'Phone Number', type: 'number' },
    { name: 'reference_name', label: 'Reference Name', type: 'text' },
    { name: 'gender', label: 'Gender', type: 'text' },
    { name: 'resume', label: 'Attach Resume', type: 'file' },
    { name: 'job_location', label: 'Location', type: 'text' },
    { name: 'date_of_availability', label: 'Date of Availability', type: 'date' },
  ];


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    fieldsConfig.forEach(field => {
      if (!formData[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    console.log(errors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      formDataObj.append(key, formData[key]);
    });

    try {
      const url = 'https://be.webkorps.com:4202/v1/job_applies/send_notifications';
      const response = await fetch(url, {
        method: 'POST',
        body: formDataObj,
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          full_name: '',
          email: '',
          phone_number: '',
          reference_name: '',
          gender: '',
          job_location: '',
          date_of_availability: ''
        });
      } else {
        alert('Form submission failed!', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="overflow-hidden ">
      <Header />
      <div className="flex justify-center overflow-hidden text-center md:text-left md:mt-4 relative w-full h-[15rem] md:h-[25rem]">
        <img

          src="https://webkorps-app.s3.us-east-1.amazonaws.com/careerBackground.webp"
          alt="About"
          className="w-full h-full object-cover opacity-70"
        />
        <div
          className="absolute inset-0 flex items-center justify-center flex-col"
          style={{
            background:
              "linear-gradient(0deg, #001833, #001833), linear-gradient(0deg, #001833, #001833)",
            opacity: 0.85,
          }}
        >
          <h1 className="text-[28px] leading-[39px] text-white font-bold font-quicksand ">Product Designer</h1>
          <h2 className="text-[13px] leading-[18px] text-white mt-2">
            Job Type: Full Time <span className="ml-2">No of Vacancies: 05</span>
          </h2>
          <button className="text-white text-[16px] leading-[22px] primary-button mt-8 font-bold">
            Apply Now
          </button>
        </div>
      </div>
      <section className="content px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full text-center text-dark job-section-blog p-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <div className="grid  grid-cols-1 md:grid-cols-3 gap-4 mt-3 md:mt-10">
            <div className="col-span-2 w-[90%] ">
              <h1 className=" basic-black  text-[14px] md:text-[33px] leading-[46px] font-bold md:text-left">
                PRODUCT DESIGNER
              </h1>
              <div className="md:mt-10">
                <p className="text-[23px] leading-[32px] basic-black mt-1 md:mt-4 md:text-left font-bold">
                  Who Are We Looking For
                </p>
                <div className="pt-2 md:pt-5 text-[16px] leading-[23px] md:text-left">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: jobDetails?.additional_skills,
                    }}
                  /> */}
                  <ul dangerouslySetInnerHTML={{
                    __html: jobDetails?.additional_skills,
                  }}></ul>
                </div>
              </div>
              <div className="md:mt-1 md:pt-2">
                <p className="text-[23px] leading-[32px]  basic-black mt-3 md:mt-4 md:text-left font-bold">
                  Bonus Points for Familiarity with
                </p>
                <div className="pt-2 md:pt-5  text-[16px] leading-[23px] md:text-left" dangerouslySetInnerHTML={{ __html: jobDetails?.additional_skills }}>

                </div>
              </div>
              <div className="mt-1 pt-2">
                <p className="text-[23px] leading-[32px]  basic-black mt-4 md:text-left font-bold">
                  Educational Requirement
                </p>
                <div className="pt-3 md:text-left ">
                  <p className="text-[16px] leading-[23px]">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going</p>
                </div>
              </div>
            </div>
            <div className="bg-[#F9F9F9] border-[#D4D4D4] border-[0.25px] mt-3 md:mt-0 md:p-5 pb-5">
              <div className="mt-4 md:mt-8 job-details justify-center items-center flex flex-col md:block">
                <button className="text-white apply-button mt-4  text-[19px] leading-[26px] font-bold md:mb-5">Apply Now</button>
                <h1 className=" text-[#000000] p-4 font-bold font-rubik text-[18px] leading-[24px] text-left pt-8 md:pb-3">
                  Job Summary
                </h1>
                {items.map((item, index) => (
                  <div key={index} className="p-4 flex flex-col md:flex-row justify-center md:justify-start items-center relative   pt-0 md:pb-10">
                    <img src={item.image} width={30} height={38} alt="text" className="mb-2 text-center md:text-left md:mb-0" />
                    <div className="ml-5">
                      <h2 className="  text-[17px] leading-[24px]  md:text-left font-rubik  items-name text-center text-[#D4D4D4]">{item.name}
                      </h2>
                      <span className="block font-medium text-[17px] leading-[24px] md:text-left font-rubik  text-center text-[#001833]">{`${jobDetails?.[item.key]}\n`}</span>
                    </div>
                  </div>
                ))}
                <Link to={`/join-us`} className="text-left float-left text-[17px] leading-[24px] font-rubik  font-medium pt-8 md:ml-5 underline">View all job</Link>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="application-form mt-10 px-[7%] md:px-0">
        <div className="w-full md:w-[920px] h-auto  m-auto">
          <form className="bg-white shadow-[#5E70CD4D] h-auto  justify-items-center rounded-[30px]  pt-[8%] pb-[8%] mb-[8%]  shadow-2xl overflow-hidden  z-50 "
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="md:w-[558px] px-[5%] md:px-0">
              <div className="mb-4   ">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="fullname">
                  Full Name
                </label>
                <input
                  className="shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="fullname"
                  type="text"
                  placeholder="Enter Fullname"
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="phone">
                  Phone Number
                </label>
                <div className="flex">
                  <input
                    className="shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="text"
                    placeholder="Enter phone number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <button className="ml-2 px-4 py-2 border-2 border-[rgba(74,61,229,0.25)] text-[rgba(74,61,229,0.25)] font-bold rounded   focus:outline-none focus:shadow-outline">
                    Verify
                  </button>


                </div>
              </div>

              <div className="mb-4">
                <label className="block text-[#000000]  text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="reference">
                  Reference Name
                </label>
                <input
                  className="shadow h-[48px] appearance-none text-[14px] leading-[16px] border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="reference"
                  type="text"
                  placeholder="Reference"
                  name="reference"
                  value={formData.reference}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2">
                  Gender
                </label>
                <div className="flex justify-between items-center">
                  <div className="flex  items-center">
                    <input
                      id="male"
                      type="radio"
                      name="gender"
                      value="Male"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Male"}
                      onChange={handleChange}
                    />
                    <label htmlFor="male" className="mr-4 font-chivo  font-medium text-[14px] leading-[16px]">Male</label>
                  </div>
                  <div className="flex  items-center">
                    <input
                      id="female"
                      type="radio"
                      name="gender"
                      value="Female"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Female"}
                      onChange={handleChange}
                    />
                    <label htmlFor="female" className="font-chivo  font-medium text-[14px] leading-[16px]">Female</label>
                  </div>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>


                </div>
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="resume">
                  Attach Resume
                </label>
                <input
                  className="shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="resume"
                  type="file"
                  name="resume"
                  accept=".pdf,.doc,.docx"
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="jobLocation">
                  Job Location
                </label>
                <select
                  className="shadow  h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="jobLocation"
                  name="jobLocation"
                  value={formData.jobLocation}
                  onChange={handleChange}
                >
                  <option>Select Options</option>
                  <option value="location1">Location 1</option>
                  <option value="location2">Location 2</option>
                  {/* Add more locations as needed */}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="date">
                  Choose a Date
                </label>
                <input
                  className="shadow text-[14px]  leading-[16px] h-[48px] appearance-none border-[1px] border-b-4 border-black rounded-tl rounded-tr rounded-bl rounded-br w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="date"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4 flex justify-between items-center">
                <input
                  id="agree"
                  type="checkbox"
                  className="mr-2 h-[54px] w-[54px] leading-tight"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleChange}
                />
                <label className="text-[#000000] font-chivo  font-medium text-[14px] leading-[16px]" htmlFor="agree">
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.              </label>
              </div>

              <button
                className="bg-[#469FD4] h-[48px] hover:bg-[#469FD4] border-[0.8px] border-b-4 border-blue-700 rounded-tl rounded-tr rounded-bl text-white font-bold  w-full py-2 px-3 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>

        </div>
      </section>
      <Footer />
    </div>
  );
}
