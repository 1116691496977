import React from "react";
import Header from "./Header";
import CardContainer from "./Card";
import Main from "./main";
import './Home.css'
import ServicesListContainer from "./serviceLists";
import TopIntregation from "./topIntregation";
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const DESCRIPTION = " "

  return (
    <div className="overflow-hidden " >
      <Helmet>
        <title>Leading IT Solutions and IT Services Provider | Webkorps</title>
        <meta
          name="description"
          content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth."
        />
        <meta property="og:url" content="https://www.webkorps.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading IT Solutions and IT Services Provider | Webkorps" />
        <meta property="og:description" content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/IT+Solutions+and+IT+Services+Provider.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com" />
        <meta name="twitter:title" content="Leading IT Solutions and IT Services Provider | Webkorps" />
        <meta name="twitter:description" content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/IT+Solutions+and+IT+Services+Provider.webp" />
        <link rel="canonical" href="https://www.webkorps.com/" />
      </Helmet>
      <Header />

      <div className="w-full h-auto md:block">
        <div className="w-full  content">
          <div className="flex overflow-hidden flex-col ipad-pro:flex-col  xl:flex-row-reverse items-start md:items-start md:space-x-8">
            {/* Right Section (Image) */}
            <div className="w-[100%] xl:w-[80%] ipad-pro:w-full xl:pl-0 ipad-pro:pl-[100px] md:pl-[25px]">
              <img src="https://webkorps-app.s3.us-east-1.amazonaws.com/demo.webp" alt="Demo " className="w-full h-[644px] xl:h-[644px] ipad-pro:h-[800px]" />
            </div>
            {/* Left Section (Text Content) */}
            <div className="w-[100%] md:w-[100%]  overflow-hidden pl-[7%] xl:pl-[130px] xl:pr-0 md:px-[20px] ipad-pro:px-[77px]" style={{ maxWidth: '95%' }}>
              <div className="mt-6 text-center  md:text-left md:mt-25 lg:mt-20 ">
                <p
                  className="font-quicksand lg:tracking-wider font-bold text-[20px] md:text-[33px] xl:text-[33px]  ipad-pro:text-[37px] md:text-[36px] leading-7 md:leading-[46.2px] lg:leading-[46.2px]"
                  style={{ color: "#1887C9" }}
                >
                  We Code Solutions, Not Just Software
                </p>
                <p
                  className="font-quicksand lg:tracking-wider text-[#0A3954]  text-[26px] md:text-[45px] xl:text-[48px] ipad-pro:text-[59px]   leading-[30px] md:leading-[60.2px] xl:leading-[67.2px] ipad-pro:leading-[70.7px] mt-4"
                  style={{ color: "#0A3954", fontWeight: 'lighter' }}
                >
                  Step Into The Digital Age <br />
                  With Our Cutting-Edge <br />
                  IT Solutions!
                </p>
              </div>

              <div className="flex flex-col md:flex-row ipad-pro:flex-row xl:flex-row items-center justify-center md:justify-start ipad-pro:items-start  ipad-pro:justify-start gap-6  md:gap-9 xl:gap-9 ipad-pro:gap-6 mt-6 sm:flex-col sm:mt-8 md:mt-12 xl:mt-16 ipad-pro:mt-10">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-4 focus:ring-blue-300 font-bold text-base md:text-lg w-[70%] sm:px-3 lg:px-3 md:py-3 md:w-[45%] xl:w-[40%] ipad-pro:w-[55%] px-3 py-3  md:px-3 text-center border-2 border-[#1887C9] text-[#1887C9] rounded-full"
                  style={{ backgroundColor: "transparent" }}
                >
                  Calculate Project Online
                </button>

                <button
                  type="button"
                  className="flex items-center align-center justify-center text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-base text-center md:text-lg  w-[70%] md:w-[45%] xl:w-[40%] ipad-pro:w-[55%]  px-3 md:px-3 py-3 sm:py-5 md:py-2 bg-[#1887C9]"
                >
                  <img

                    src="https://webkorps-app.s3.us-east-1.amazonaws.com/Phone.webp"

                    alt="Icon"
                    className="w-5 h-5 mr-2 md:w-10 md:h-10"
                  />
                  Schedule a Call
                </button>
              </div>
              <div className=" w-full  md:mt-10 mt-14  flex flex-col items-center sm:items-center md:items-start lg:items-start gap-6  ">
                <p
                  className="font-quicksand font-bold text-[28px] md:text-[28px] lg:text-[28px] leading-6 md:leading-7 lg:leading-[40px]"
                  style={{ color: "#000000" }}
                >
                  Our Official Partners:
                </p>

                <div className="flex flex-wrap justify-center gap-6 xl:gap-6 md:gap-4 ipad-pro:gap-4 ">
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] p-2 border-[0.63px] border-black rounded-[15px]  flex justify-center items-center shadow-lg">
                    <img

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/cluch6.webp"
                      alt="Clutch 1"
                      className="max-w-full max-h-full"
                    />
                  </div>
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px] p-2 flex justify-center items-center shadow-lg">
                    <img

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/cluch5.webp"
                      alt="Clutch 2"
                      className="max-w-full max-h-full"
                    />
                  </div>
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px]  p-2 flex justify-center items-center shadow-lg">
                    <img

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/microsoft.webp"
                      alt="Clutch 2"
                      className="h-5 max-w-full"
                    />
                    <p className="sm:ml-1 md:ml-1 lg:ml-1 font-medium text-xs sm:text-sm md:text-base">Microsoft</p>
                  </div>

                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px]  p-2 flex justify-center items-center shadow-lg">
                    <img

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/hubspot.webp"
                      alt="Clutch 2"
                      className="max-w-full h-5"
                    />
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>



      <ServicesListContainer />

      <CardContainer />
      <TopIntregation />

      <Main />
    </div>
  );
};
export default Home;
