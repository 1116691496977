import React, { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import Slider from 'react-slick';

const Card = ({ image }) => {
  return (
    <div className="md:w-[256px] xl:w-[256px] ipad-pro:w-[350px] ipad-pro:h-[412px] w-[100%]  h-[412px] md:h-[312px] xl:h-[312px]  rounded-[16px] overflow-hidden mx-auto md:mx-1">
      <img src={image} alt="card" className="w-full  h-full object-cover" />
    </div>
  );
};

const AchievementCardContainer = () => {
  const cards = [
    "https://webkorps-app.s3.us-east-1.amazonaws.com/achivment1.webp",
    "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement4.webp",
    "https://webkorps-app.s3.us-east-1.amazonaws.com/Achivement5.webp",
    "https://webkorps-app.s3.us-east-1.amazonaws.com/webAchivement.webp",
    "https://webkorps-app.s3.us-east-1.amazonaws.com/mobileAchivment.webp",
    "https://webkorps-app.s3.us-east-1.amazonaws.com/CMMIAchivment.webp",
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "0px", // Ensures there’s no extra space on the sides
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // For iPad Air (820px width in portrait mode)
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const webSliderRef = useRef(null);
  const [webDirection, setWebDirection] = useState('right');

  useEffect(() => {
    const autoPlayWeb = setInterval(() => {
      if (webSliderRef.current) {
        webDirection === 'right' ? webSliderRef.current.slickNext() : webSliderRef.current.slickPrev();
      }
    }, 5000);

    return () => clearInterval(autoPlayWeb);
  }, [webDirection]);

  return (
    <div className="pb-10">
      <Slider
        {...settings}
        ref={webSliderRef}
        className="mt-5 max-w-full"
        beforeChange={(oldIndex, newIndex) => {
          if (newIndex === 0 && webDirection === 'left') {
            setWebDirection('right'); 
          }
        }}
      >
        {cards.map((image, index) => (
          <div key={index} className="flex justify-center  pl-5 ipad-pro:pl-0  xl:pl-0 md:pl-0 md:justify-start">
            <Card image={image} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AchievementCardContainer;
