import React, { useEffect, useState } from "react";
import Header from "../sharedComponents/Header";
import { Link } from "react-router-dom";
import Footer from "../sharedComponents/footer";
import { Helmet } from 'react-helmet-async';

export default function JoinUs() {
  // const jobOpenings = [
  //   { id: 1, designation: "Software Engineer", experience: "2-5 years" },
  //   { id: 2, designation: "Product Manager", experience: "3-7 years" },
  //   { id: 3, designation: "UI/UX Designer", experience: "1-4 years" },
  //   { id: 4, designation: "Data Scientist", experience: "3-5 years" },
  // ];
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleSelect = (index) => {
    setSelectedIndex(index);
  };
  const [jobOpenings, setJobOpenings] = useState([]);

  useEffect(() => {
    fetch("https://be.webkorps.com:4202/v1/career_jobs")
      .then((response) => response.json())
      .then((data) => setJobOpenings(data))
      .catch((error) => console.error("Error:", error));
  }, []);
  const cardData = [
    {
      title: "Team Work",
      description:
        "Go to this step by step guideline process on how to certify for your weekly benefits:",
      linkText: "See our guideline",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/teamIcon.webp",
      backgroundColor: "#E7F2FF",
    },
    {
      title: "Secured Future",
      description:
        "Learn how to effectively manage your projects with our comprehensive guide:",
      linkText: "Read more",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/refreshIcon.webp",
      backgroundColor: "#F1F7E8",
    },
    {
      title: "Learning Opportunity",
      description:
        "Explore the opportunities and tools available for advancing your career:",
      linkText: "Discover more",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/contact.webp",
      backgroundColor: "#EFF2F5",
    },
    {
      title: "Upgrade Skills",
      description:
        "Enhance your Upgrade skills with our expert tips and strategies:",
      linkText: "Get the tips",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/bar.webp",
      backgroundColor: "#FFEEED",
    },
  ];
  return (
    <div className="overflow-hidden " >
      <Helmet>
        <title>Job Openings and Career Opportunities at Webkorps</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry."
        />
        <meta property="og:url" content="https://www.webkorps.com/join-us" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Job Openings and Career Opportunities at Webkorps" />
        <meta property="og:description" content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Job+Openings+and+Career+Opportunities+at+Webkorps.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/join-us" />
        <meta name="twitter:title" content="Job Openings and Career Opportunities at Webkorps" />
        <meta name="twitter:description" content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Job+Openings+and+Career+Opportunities+at+Webkorps.webp" />
        <link rel="canonical" href="https://www.webkorps.com/join-us" />
      </Helmet>
      <Header />
      <div className="flex justify-center overflow-hidden  md:mt-4 relative w-full h-[20rem] md:h-[25rem]">
        <img

          src="https://webkorps-app.s3.us-east-1.amazonaws.com/careerBackground.webp"
          alt="About"
          className="w-full h-full object-fill opacity-70"
        />
        <div
          className="absolute inset-0 flex items-center justify-center flex-col"
          style={{
            background:
              "linear-gradient(0deg, #001833, #001833), linear-gradient(0deg, #001833, #001833)",
            opacity: 0.85,
          }}
        >
          <h1 className="text-4xl md:text-5xl text-white font-bold">Join Us</h1>
          <h2 className="text-center text-white mt-4 px-4 md:px-0">
            Step into a world where innovation meets excellence. Be part of <br /> a team that’s shaping   the future of technology and redefining <br /> possibilities. Together, let's create, inspire, and achieve greatness!
          </h2>
          <button className="text-white text-base tracking-wide font-bold bg-[#1887C9] px-4 py-2 rounded-md mt-4">
            See Open Position
          </button>
        </div>
      </div>
      <div className=" px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full  overflow-hidden text-center md:text-left mb-3.5   mt-6 md:mt-16">
        <div className="grid grid-cols-1 gap-5 xl:gap-[20%] ipad-pro:gap-[10%] md:grid-cols-1   xl:grid-cols-[40%_60%] ipad-pro:grid-cols-[40%_60%] h-full xl:h-[450px] ipad-pro:h-auto md:h-auto ">
          <div>
            <h4 className="basic-black font-bold text-[23px] md:text[23px]">Benefits</h4>
            <h1 className="font-quicksand text-h3Custom leading-h3Custom font-bold md:text-left ">
              Why you Should Join Our <br />
              Awesome Team
            </h1>
            <p className="font-quicksand text-smCustom text-center md:text-left font-light leading-4.5  mt-3 mb-3 ">
              we want to feel like home when you are working at Webkorps & for that we have curated a great set of benefits for you.It all starts with the free lunch!
            </p>
          </div>
          <div className="w-[100%] xl:w-[70%] ipad-pro:w-[85%] grid gap-2 grid-cols-1 md:grid-cols-2 ">
            {cardData.map((card, index) => (
              <div key={index} className="max-w-sm flex flex-col mb-3 justify-center md:justify-start md:items-start  items-center md:flex-col ">
                <div
                  className="w-[74px] h-[76px] mb-0 md:mb-3  flex items-center justify-center"
                  style={{
                    backgroundColor: card.backgroundColor,
                  }}
                >
                  <img

                    src={card.image}
                    alt={card.title}
                    className="w-12 h-12"
                  />
                </div>
                <div className="mt-0 md:mt-8">
                  <h5 className="mb-2 font-bold tracking-tight basic-black text-[23px] leading-[32.2px]">
                    {card.title}
                  </h5>
                  <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 basic-black text-sm">
                    {card.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="job-list overflow-hidden  section-bg-color p-7 pb-3">
        <div className="content mx-auto  w-full max-w-screen-xl text-center text-dark md:mb-40">
          <h1 className=" basic-black font-bold text-[23px] leading-[32.2px]">
            Come join us
          </h1>
          <h4 className="basic-black font-bold text-3xl mt-2">
            Career Openings
          </h4>
          <p className="text-base mt-4 font-normal ">
            We’re always looking for creative, talented self-starters to join the Webkorps
            family.
          </p>
          <p>Check out our open roles below and fill out an application.</p>
          <div className="container mt-6  w-full ">
            {jobOpenings.length !== 0 ?
              <div className="grid grid-cols-1 xl:grid-cols-[30%_67%] md:grid-cols-[25%_70%] ipad-pro:grid-cols-[25%_63%] h-[20%] ipad-pro:gap-10 gap-6">
                <div className="block md:relative">
                  <ul className=" basic-black text-[13px] leading-[13.4px] font-bold md:absolute md:top-0  md:right-10  justify-center md:justify-items-start font-semibold line-height-1 space-y-2">
                    {[
                      "HT & Admin",
                      "Engineering (20)",
                      "Support",
                      "Design",
                      "Digital Marketing",
                    ].map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(index)}
                        className={`cursor-pointer pb-5 font-bold rounded ${selectedIndex === index
                          ? "text-[#D2232A]"
                          : "text-black"
                          }`}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="">
                  {jobOpenings.map((job) => (
                    <Link to={`/join-us/${job.id}`} key={job.id}>
                      <div className="block px-4 py-3 mb-6 bg-white md:h-[50%] items-center align-center  justify-center content-center drop-shadow cursor-pointer">
                        <div className="grid grid-cols-1 md:grid-cols-4 items-center justify-center text-center font-bold  gap-4">
                          <div className=" text-[19px] leading-[26px]">{job.designation}</div>
                          <div className="">
                            <p className="text-[15px] md:text-[11px] text-[#D4D4D4] ">Experince</p>
                            <p className="text-[13px] md:text-[19px] leading-[26px]">{job.experience}</p>
                          </div>
                          <div className=" md:col-span-2">
                            <p className="text-[15px] md:text-[11px] text-[#D4D4D4] ">Deadline</p>
                            <p className="text-[13px] md:text-[19px] leading-[26px]]"> 2021-05-08</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div> : <div>
                NO DATA AVAILABLE
              </div>}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}