import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Card = ({ image, title, descriptions, tag }) => {
  return (
    <div className="relative  w-full  sm:w-[90%] md:w-[100%] lg:w-[95%] h-[100%] rounded-[7%] overflow-hidden cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-300 mx-auto">
      <div className="card-image-container relative w-full h-[250px] md:h-[295px] transition-transform duration-300 cursor-pointer">
        <img

          src={image}
          alt="card background"
          className="w-full h-[100%] object-cover transition-transform duration-300 hover:scale-110"
        />
      </div>

      <div className="absolute inset-0 flex flex-col items-start justify-end bg-black bg-opacity-50 backdrop-blur-sm text-white opacity-0 hover:opacity-100 transition-opacity duration-300 p-4">
        {tag && (
          <p className="bg-gray-200 text-gray-700 rounded-full px-3 py-1 text-sm font-semibold mb-2">
            {tag}
          </p>
        )}
        <p className="text-white font-bold text-[16px] md:text-[20px] lg:text-[24px] leading-[20px] md:leading-[28px] lg:leading-[32px]">
          {title}
        </p>
        <p className="text-white text-[12px] md:text-[13px] leading-[18px] md:leading-[20px] mt-1">
          {descriptions}
        </p>
      </div>
    </div>
  );
};


const CardContainer = () => {
  const webcards = [
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/asset.webp", title: "Asset Panda", descriptions: `Asset Panda's asset inventory management tool works on smartphones, tablets, and computers, so you always know the status of your assets.`, tag: "Asset Tracking & Management" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/crypto.webp", title: "Cryoport", descriptions: `Cryoport Systems offers integrated temperature-controlled supply chain solutions.`, tag: "Supply Chain & Logistic" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/paypal.webp", title: "PayPal", descriptions: `PayPal is a secure platform that simplifies global money transactions.`, tag: "FinTech Services" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/sonic.webp", title: "Sonic Healthcare", descriptions: `Sonic Healthcare provides medical diagnostics and lab services worldwide.`, tag: "Hospitality Services" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/wendy.webp", title: "Wendy’s", descriptions: `Wendy's is a fast-food chain known for fresh beef and square burgers.`, tag: "Hospitality & Food" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/medhost.webp", title: "Medhost", descriptions: `MEDHOST provides healthcare technology to streamline hospital operations.`, tag: "Healthcare" },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/abpproj.webp", title: "ABP News", descriptions: `ABP News is a leading Indian media organization that delivers news and updates through multiple platforms.
The company offers a mobile app that provides real-time news, personalized content, and interactive features for users.`, tag: "Telecommunication"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/scoutrproj.svg", title: "Scoutr", descriptions: `Scoutr is a talent management platform that connects job seekers with positions based on their skills and aspirations.
The platform uses a sophisticated algorithm to match candidates with roles, streamlining the hiring process for companies.`, tag: "Information & Technology"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/cloudshim.webp", title: "Cloudshim", descriptions: `CloudShim is a cloud management platform that helps organizations optimize their cloud resources and manage costs.
The platform provides real-time insights into cloud usage, allowing businesses to identify inefficiencies and improve operations`, tag: "Cloud based"
    },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/rocketscreen.webp", title: "Rocketscreens", descriptions: `RocketScreens`, tag: "Information & Technology" },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/verizonproj.webp", title: "Verizon", descriptions: `Verizon is a global telecommunications leader, providing wireless, broadband, and media services.
The company is focused on integrating advanced technologies like 5G and IoT to enhance connectivity and operational efficiency`, tag: "FinTech Services"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/cardinalproj.svg", title: "Cardinal Hire", descriptions: `CardinalHire is a recruitment platform that bridges the gap between tech talent and innovative companies.
The platform facilitates faster placements for roles like software engineers, designers, and product managers`, tag: "Information Technology"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/cignaproj.svg", title: "Cigna", descriptions: `Cigna is a Fortune 15 global healthcare and insurance company serving over 175 million customers worldwide.
They offer a broad portfolio of health, life, accident insurance, and Medicare/Medicaid products`, tag: "Heatlthcare"
    },
  ];

  const mobcards = [
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/paybal.webp", title: "Pebble", descriptions: `Pebble makes smartwatches with long battery life and user-friendly interfaces.`, tag: "E-Commerce" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/knProject.webp", title: "My Kia", descriptions: `MyKia enhances the experience of Kia owners by providing personalized services.`, tag: "Automative & Manufacturing" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/footino.webp", title: "Footino", descriptions: `Footino is a platform offering real-time football match updates and insights.`, tag: "Sports & Fitness" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/food.webp", title: "Fast Home", descriptions: `Fast Home delivers meals from local restaurants to your door quickly and reliably.`, tag: "Hospitality & Food" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/tonic.webp", title: "Tonik", descriptions: `Tonik is a digital bank offering easy, instant banking solutions.`, tag: "FinTech Services" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/cannopie.webp", title: "Cannopie", descriptions: `Cannopie offers support and resources for pregnant women.`, tag: "Healthcare" },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/bhaibhandhuProj.svg", title: "Bhai Bandhu", descriptions: `Bhai Bandhu is a B2B marketplace that connects wholesalers and retailers in the Indian ethnic wear market.
The platform allows wholesalers to create product catalogs, manage orders, and streamline communication with retailers.`, tag: "E-Commerce"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/ourProject2.svg", title: "Owens Corning", descriptions: `Owens Corning is a global leader in insulation, roofing, and fiberglass composites manufacturing.
The company focuses on innovation and sustainability, adapting to environmental regulations and market demands.`, tag: "Real state"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/ourProject3.svg", title: "ACIMA", descriptions: ` Acima is a lease-to-own service provider that allows customers to purchase items without traditional credit.
The platform offers flexible payment options, making it easier for customers to lease products from a variety of retailers.`, tag: "FINTECH"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/Ourprojects4.webp", title: "ABB", descriptions: `ABB is a global leader in electrification and automation technologies, serving industries around the world.
They focus on integrating IoT technologies to enhance operational efficiency and drive performance across their business sectors`, tag: "AI & iOT"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/inkindProject.webp", title: "InKind", descriptions: ` inKind is a platform that rewards loyal customers while supporting local restaurants with cashback and exclusive offers.
The app simplifies the process of earning and redeeming rewards for diners, encouraging repeat visits to participating restaurants`, tag: "Hospitality & Food"
    },
    {
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/stylaProj.svg", title: "Styla", descriptions: ` Styla is a storefront technology company offering solutions for e-commerce platforms like BigCommerce and Shopware.
Their key products include a Progressive Web App (PWA) storefront, no-code page builder, and image optimization tools.`, tag: "E Commerce"
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const webSliderRef = useRef(null);
  const mobSliderRef = useRef(null);
  const [webDirection, setWebDirection] = useState('right');
  const [mobDirection, setMobDirection] = useState('left');

  // Auto-play for web slider
  useEffect(() => {
    const autoPlayWeb = setInterval(() => {
      if (webSliderRef.current) {
        webDirection === 'right' ? webSliderRef.current.slickNext() : webSliderRef.current.slickPrev();
      }
    }, 5000);

    return () => clearInterval(autoPlayWeb);
  }, [webDirection]);

  // Auto-play for mobile slider
  useEffect(() => {
    const autoPlayMob = setInterval(() => {
      if (mobSliderRef.current) {
        mobDirection === 'right' ? mobSliderRef.current.slickNext() : mobSliderRef.current.slickPrev();
      }
    }, 5000);

    return () => clearInterval(autoPlayMob);
  }, [mobDirection]);

  return (
    <div className="mt-14 w-full text-center sm:text-center md:text-left lg:text-left h-auto px-[7%] xl:px-[150px] ipad-pro:px-[100px]">
      <div className="flex flex-col xl:flex-row ipad-pro:flex-col items-center md:items-start sm:items-center xl:items-center ipad-pro:items-start  gap-[9%] xl:gap-[9%] ipad-pro:gap-6">
        <p className="font-quicksand text-left  font-bold text-[32px] md:text-[36px] xl:text-[48px] ipad-pro:text-[41px] leading-[36px] md:leading-[48px] xl:leading-[67.2px] ipad-pro:leading-[47px]">
          Our Projects<span style={{ color: '#1887C9' }}>.</span>
        </p>
        <p className="font-quicksand mt-2 text-[14px] md:text-[16px] lg:text-[18px] leading-[22px] lg:leading-[24px] max-w-full xl:w-[60%] ipad-pro:max-w-full">
          We are driven by a passion for excellence, focusing on delivering innovative software solutions and user-friendly mobile apps. Our goal is to achieve the highest level of client satisfaction, as demonstrated by our success stories and commitment to quality in every project we undertake.        </p>
      </div>

      <div className="mt-6 ">
        <Slider {...settings} ref={webSliderRef}>
          {webcards.map((card, index) => (
            <div className="px-2">
              <Card key={index} image={card.image} title={card.title} descriptions={card.descriptions} tag={card.tag} />
            </div>
          ))}
        </Slider>
      </div>

      <div className="mt-6 ">
        <Slider {...settings} ref={mobSliderRef}>
          {mobcards.map((card, index) => (
            <div className="px-2">
              <Card key={index} image={card.image} title={card.title} descriptions={card.descriptions} tag={card.tag} />
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
};

export default CardContainer;
