import React, { useState } from "react";
import Header from "../sharedComponents/Header";
import Footer from "../sharedComponents/footer";
import { Helmet } from "react-helmet-async";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const AboutUs = () => {
  const offices = [
    {
      location: "Indore, Ind. (Headquarters)",
      address:
        "4th Floor, Winway World Offices,Vijay Nagar, Indore, Madhya Pradesh 452010",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/indoreOffice.webp",
      email: "hr@webkorps.com",
      region: "Asia Pacific",
    },
    {
      location: "Pune, Ind.",
      address:
        "Trios Co-working, 3rd floor, Lalwani Icon, off New Airport Road, Sakore Nagar, Viman Nagar, Pune, Maharashtra 411014",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/puneOffice.webp",
      email: "hr@webkorps.com",
      region: "Asia Pacific",
    },
    {
      location: "Noida ,Ind.",
      address:
        " 1309 Coffeen Ave. STE B1,<br/> Sheridan WY, 82801 United States",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/wilimingtonOffice.webp",
      email: "hr@webkorps.com",
      region: "USA",
    },
  ];
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    contactNumber: '',
    message: '',
  });
  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, contactNumber: value }));
  };
  const [errors, setErrors] = useState({});
  const validateField = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        if (!value) {
          errorMessage = 'Name is required.';
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          errorMessage = 'Name can only contain letters and spaces.';
        }
        break;
      case 'email':
        if (!value) {
          errorMessage = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = 'Email is invalid.';
        }
        break;
      case 'company':
        if (!value) {
          errorMessage = 'Company is required.';
        }
        break;
      case 'contactNumber':
        if (!value) {
          errorMessage = 'Contact Number is required.';
        } else if (!/^\d+$/.test(value)) {
          errorMessage = 'Contact Number can only contain numbers.';
        }
        break;
      case 'message':
        if (!value) {
          errorMessage = 'Message is required.';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((field) => validateField(field, formData[field]));
    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      formDataObj.append(key, formData[key]);
    });
    if (Object.values(errors).every((error) => error === '')) {
      console.log('Form submitted successfully', formData);
      try {
        const url = 'https://be.webkorps.com:4202/v1/contact_us';
        const response = fetch(url, {
          method: 'POST',
          body: formDataObj,
        });
        console.log(response, "response")

        alert('Form submitted successfully!');
        setFormData({
          name: '',
          email: '',
          company: '',
          contactNumber: '',
          message: '',
        });

      } catch (error) {
        alert('Form submission failed!', error.statusText);
        setErrors({});
        console.error('Error:', error);
      }

    }
  };
  const groupedOffices = offices.reduce((acc, office) => {
    if (!acc[office.region]) {
      acc[office.region] = [];
    }
    acc[office.region].push(office);
    return acc;
  }, {});
  return (
    <div className="overflow-hidden ">
      <Helmet>
        <link rel="canonical" href="https://www.webkorps.com/contact" />
      </Helmet>
      <Header />
      <div className="flex  flex-col justify-center mt-0 md:mt-[1.0rem] text-center md:text-center xl:text-left ipad-pro:text-left  relative w-full">
        <div className="w-full ">
          <div className="flex   md:items-center xl:items-start ipad-pro:items-start items-center   px-[8%] xl:px-[160px] md:px-10 ipad-pro:px-[100px]   flex-col sm:flex-col md:flex-col xl:flex-row ipad-pro:flex-row   ">
            <div className="flex flex-col xl:w-[50%] ipad-pro:w-[100%] w-[100%]  xl:mt-[185px]  ipad-pro:mt-[185px] mt-0 md:mt-0 sm:mt-0">
              <h2 className="text-[28px] leading-[39px] font-bold mb-4">Your Vision matters to us.</h2>
              <p className="text-[19px] leading-[26px] text-gray-600 mb-6 xl:w-[70%] ipad-pro:w-[90%] md:w-full w-full  px-1">
                Expect a prompt response from our team as we work towards delivering the best solution for your business.              </p>
            </div>
            <div className="relative z-20 bg-white md:w-[480px] xl::w-[480px] w-auto ipad-pro:w-[480px] xl:h-[560px] ipad-pro:h-[560px] md:h-[560px] h-[600px] xl:top-[48px] md:top-[48px] ipad-pro:top-[48px] top-0 mb-7 xl:mb-0 md:mb-0 ipad-pro:mb-0    rounded-[20px] shadow-lg border border-gray-600">
              <div className="bg-gray-100  border-b border-gray-500  w-[100%] h-30  pb-2 p-6 rounded-tl-[20px] rounded-tr-[20px]">
                <h3 className="text-[33px] leading-[46px] font-semibold font-quicksand mb-1">Contact Us<span style={{ color: '#1887C9' }}>.</span></h3>
                <p className="text-[16px] leading-[22px] font-quicksand mb-2 ">
                  You can also email us directly at {" "}
                  <a href="mailto:contact@webkorps.com" style={{ color: '#1887C9' }}>
                    contact@webkorps.com
                  </a>
                </p>
              </div>
              <form className="bg-white p-6" onSubmit={handleSubmit}>
                <div className="mb-4 flex space-x-4">
                  <div className="flex-1 mb-5">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      value={formData.name}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px] p-2 text-[19px] leading-[26px] border-b ${errors.name ? 'border-red-600' : 'border-gray-500'
                        }`}
                      required
                    />
                    {errors.name && <p className="text-red-600 text-xs">{errors.name}</p>}
                  </div>
                  <div className="flex-1 mb-5">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      value={formData.email}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px] p-2 text-[19px] leading-[26px] border-b ${errors.email ? 'border-red-600' : 'border-gray-500'
                        }`}
                      required
                    />
                    {errors.email && <p className="text-red-600 text-xs">{errors.email}</p>}
                  </div>
                </div>
                <div className="mb-4 flex space-x-4">
                  <div className="flex-1 mb-5">
                    <input
                      type="text"
                      name="company"
                      placeholder="Company*"
                      value={formData.company}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px] p-2 text-[19px] leading-[26px] border-b ${errors.company ? 'border-red-600' : 'border-gray-500'
                        }`}
                      required
                    />
                    {errors.company && <p className="text-red-600 text-xs">{errors.company}</p>}
                  </div>
                  <div className="flex-1 mb-5">
                    <input
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      placeholder="Country*"
                      className="xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px] p-2 text-[19px] leading-[26px] border-b border-gray-500"
                      required
                    />
                    {errors.country && <p className="text-red-600 text-xs">{errors.country}</p>}
                  </div>
                </div>
                <div className="mb-9">
                  <div className=" flex">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={formData.contactNumber}
                      onChange={handlePhoneChange}
                      placeholder="Contact Number*"
                      className="xl:w-[405px] md:w-[405px] w-[315px] ipad-pro:w-[405px] h-[39px] p-2 text-[19px] leading-[26px] border-b border-gray-500 p-2"
                      required
                    />
                    {errors.contactNumber && <p className="text-red-600 text-xs">{errors.contactNumber}</p>}
                  </div>
                </div>
                <div className="mb-3">
                  <textarea
                    name="message"
                    placeholder="Message*"
                    value={formData.message}
                    onChange={handleChange}
                    className={`xl:w-[405px] md:w-[405px] w-[315px] ipad-pro:w-[405px] h-[49px] p-2 text-[19px] leading-[26px] border-b ${errors.message ? 'border-red-600' : 'border-gray-500'
                      } overflow-y-auto resize-none`}
                    rows="1"
                    required
                  />

                  {errors.message && <p className="text-red-600 text-xs">{errors.message}</p>}
                </div>
                <button type="submit" className="bg-[#469FD4] w-[180px] text-white px-4 py-3 mt-6 rounded-md">
                  Send
                </button>
              </form>

              <p className="text-[16px] leading-[22px] font-light  p-6 py-3">
                For Job-related queries email us at{" "}
                <a href="mailto:hr@webkorps.com" style={{ color: '#1887C9' }}>
                  hr@webkorps.com
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* Offices Section */}
        <div className="relative z-0 w-full pb-6 md:pb-10 md:pb-20 flex flex-col justify-center" style={{ backgroundColor: '#626D78' }}>
          <div className=" w-full px-[8%] md:px-10 xl:px-[160px] ipad-pro:px-[100px]">
            <h2 className="text-2xl font-bold text-[40px] leading-[56.2px] text-white  mt-3 md:mt-24">Our Offices<span style={{ color: '#1887C9' }}>.</span></h2>
          </div>
          <div className="  px-[8%] xl:px-[160px] md:px-10 ipad-pro:px-[100px] gap-3 flex  flex-col xl:flex-row  ipad-pro:flex-col mt-5">
            <div className="w-full xl:w-[70%] ipad-pro:w-full">
              <h3 className="text-[36px] md:text-[40px] leading-[56px] font-bold text-white mb-3">Asia Pacific</h3>
              <div className="flex flex-col md:flex-row gap-6 md:gap-0 md:space-x-4 overflow-x-auto">
                {groupedOffices["Asia Pacific"].map((office, index) => (
                  <div key={index} className="bg-white  shadow-md overflow-hidden w-full md:w-[50%] h-[384px] ">
                    <img src={office.imgSrc} alt={office.location} className="w-full h-48 object-cover" />
                    <div className="p-2 md:p-4">
                      <h3 className="text-[19px] leading-[26px] font-semibold mb-2">{office.location}</h3>
                      <div className="flex flex-col md:flex-row text-center md:text-left items-center gap-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 13.5C11.2583 13.5 10.5333 13.2801 9.91661 12.868C9.29993 12.456 8.81928 11.8703 8.53545 11.1851C8.25162 10.4998 8.17736 9.74584 8.32206 9.01841C8.46675 8.29098 8.8239 7.6228 9.34835 7.09835C9.8728 6.5739 10.541 6.21675 11.2684 6.07206C11.9958 5.92736 12.7498 6.00162 13.4351 6.28545C14.1203 6.56928 14.706 7.04993 15.118 7.66661C15.5301 8.2833 15.75 9.00832 15.75 9.75C15.7488 10.7442 15.3533 11.6973 14.6503 12.4003C13.9473 13.1033 12.9942 13.4988 12 13.5ZM12 7.5C11.555 7.5 11.12 7.63196 10.75 7.8792C10.38 8.12643 10.0916 8.47783 9.92127 8.88896C9.75098 9.3001 9.70642 9.7525 9.79323 10.189C9.88005 10.6254 10.0943 11.0263 10.409 11.341C10.7237 11.6557 11.1246 11.87 11.561 11.9568C11.9975 12.0436 12.4499 11.999 12.861 11.8287C13.2722 11.6584 13.6236 11.37 13.8708 11C14.118 10.63 14.25 10.195 14.25 9.75C14.2494 9.15345 14.0122 8.5815 13.5903 8.15967C13.1685 7.73784 12.5966 7.5006 12 7.5Z" fill="#1887C9" />
                        <path d="M12 22.5L5.67301 15.0382C5.58509 14.9262 5.49809 14.8135 5.41201 14.7C4.33179 13.276 3.74799 11.5373 3.75001 9.75C3.75001 7.56196 4.6192 5.46354 6.16637 3.91637C7.71355 2.36919 9.81197 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.2517 11.5365 19.6682 13.2743 18.5888 14.6978L18.588 14.7C18.588 14.7 18.363 14.9955 18.3293 15.0353L12 22.5ZM6.60975 13.7963C6.60975 13.7963 6.7845 14.0272 6.82425 14.0767L12 20.181L17.1825 14.0685C17.2155 14.0272 17.391 13.7948 17.3918 13.794C18.2747 12.6309 18.7518 11.2103 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25001 7.95979 5.25001 9.75C5.24815 11.2112 5.72584 12.6327 6.60975 13.7963Z" fill="#1887C9" />
                      </svg>
                        <p className="text-[13px] leading-[18px] text-[#000000] font-light w-full md:w-[70%]">{office.address} </p></div>
                      <a href={`mailto:${office.email}`} className="text-[#1887C9] font-bold text-[13px] xl:absolute ipad-pro:mt-10 bottom-[14%] flex gap-1 flex-col md:flex-row text-center items-center md:items-end  mb-0 block">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_416_888)">
                            <path d="M23.1429 2.56653H0.857143C0.383036 2.56653 0 2.94956 0 3.42367V20.5665C0 21.0406 0.383036 21.4237 0.857143 21.4237H23.1429C23.617 21.4237 24 21.0406 24 20.5665V3.42367C24 2.94956 23.617 2.56653 23.1429 2.56653ZM22.0714 5.53439V19.4951H1.92857V5.53439L1.18929 4.95849L2.24196 3.60581L3.38839 4.49778H20.6143L21.7607 3.60581L22.8134 4.95849L22.0714 5.53439ZM20.6143 4.4951L12 11.1915L3.38571 4.4951L2.23929 3.60314L1.18661 4.95581L1.92589 5.53171L11.0759 12.646C11.339 12.8504 11.6628 12.9614 11.996 12.9614C12.3292 12.9614 12.6529 12.8504 12.9161 12.646L22.0714 5.53439L22.8107 4.95849L21.758 3.60581L20.6143 4.4951Z" fill="#1887C9" />
                          </g>
                          <defs>
                            <clipPath id="clip0_416_888">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg> {office.email}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full xl:w-[35%] ipad-pro:w-[50%] md:w-[50%]">
              <h3 className="text-[36px] md:text-[40px] leading-[56px] font-bold text-white mb-3">USA</h3>
              <div className="bg-white  shadow-md overflow-hidden h-[384px]">
                <img src={groupedOffices["USA"][0].imgSrc} alt={groupedOffices["USA"][0].location} className="w-full h-48 object-cover" />
                <div className="p-2">
                  <h3 className="text-[19px] leading-[26px] font-semibold mb-2">{groupedOffices["USA"][0].location}</h3>
                  <div className="flex flex-col md:flex-row text-center items-center  gap-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13.5C11.2583 13.5 10.5333 13.2801 9.91661 12.868C9.29993 12.456 8.81928 11.8703 8.53545 11.1851C8.25162 10.4998 8.17736 9.74584 8.32206 9.01841C8.46675 8.29098 8.8239 7.6228 9.34835 7.09835C9.8728 6.5739 10.541 6.21675 11.2684 6.07206C11.9958 5.92736 12.7498 6.00162 13.4351 6.28545C14.1203 6.56928 14.706 7.04993 15.118 7.66661C15.5301 8.2833 15.75 9.00832 15.75 9.75C15.7488 10.7442 15.3533 11.6973 14.6503 12.4003C13.9473 13.1033 12.9942 13.4988 12 13.5ZM12 7.5C11.555 7.5 11.12 7.63196 10.75 7.8792C10.38 8.12643 10.0916 8.47783 9.92127 8.88896C9.75098 9.3001 9.70642 9.7525 9.79323 10.189C9.88005 10.6254 10.0943 11.0263 10.409 11.341C10.7237 11.6557 11.1246 11.87 11.561 11.9568C11.9975 12.0436 12.4499 11.999 12.861 11.8287C13.2722 11.6584 13.6236 11.37 13.8708 11C14.118 10.63 14.25 10.195 14.25 9.75C14.2494 9.15345 14.0122 8.5815 13.5903 8.15967C13.1685 7.73784 12.5966 7.5006 12 7.5Z" fill="#1887C9" />
                    <path d="M12 22.5L5.67301 15.0382C5.58509 14.9262 5.49809 14.8135 5.41201 14.7C4.33179 13.276 3.74799 11.5373 3.75001 9.75C3.75001 7.56196 4.6192 5.46354 6.16637 3.91637C7.71355 2.36919 9.81197 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.2517 11.5365 19.6682 13.2743 18.5888 14.6978L18.588 14.7C18.588 14.7 18.363 14.9955 18.3293 15.0353L12 22.5ZM6.60975 13.7963C6.60975 13.7963 6.7845 14.0272 6.82425 14.0767L12 20.181L17.1825 14.0685C17.2155 14.0272 17.391 13.7948 17.3918 13.794C18.2747 12.6309 18.7518 11.2103 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25001 7.95979 5.25001 9.75C5.24815 11.2112 5.72584 12.6327 6.60975 13.7963Z" fill="#1887C9" />
                  </svg><p className="text-[13px] leading-[18px] text-[#000000] font-light text-left" dangerouslySetInnerHTML={{ __html: groupedOffices["USA"][0].address }} /></div>
                  <a href={`mailto:${groupedOffices["USA"][0].email}`} className="text-[#1887C9] font-bold text-[13px] xl:absolute ipad-pro:mt-10 bottom-[14%] flex items-center flex-col md:flex-row text-center gap-1  items-center md:items-end mt-2 block">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_416_888)">
                        <path d="M23.1429 2.56653H0.857143C0.383036 2.56653 0 2.94956 0 3.42367V20.5665C0 21.0406 0.383036 21.4237 0.857143 21.4237H23.1429C23.617 21.4237 24 21.0406 24 20.5665V3.42367C24 2.94956 23.617 2.56653 23.1429 2.56653ZM22.0714 5.53439V19.4951H1.92857V5.53439L1.18929 4.95849L2.24196 3.60581L3.38839 4.49778H20.6143L21.7607 3.60581L22.8134 4.95849L22.0714 5.53439ZM20.6143 4.4951L12 11.1915L3.38571 4.4951L2.23929 3.60314L1.18661 4.95581L1.92589 5.53171L11.0759 12.646C11.339 12.8504 11.6628 12.9614 11.996 12.9614C12.3292 12.9614 12.6529 12.8504 12.9161 12.646L22.0714 5.53439L22.8107 4.95849L21.758 3.60581L20.6143 4.4951Z" fill="#1887C9" />
                      </g>
                      <defs>
                        <clipPath id="clip0_416_888">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{groupedOffices["USA"][0].email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AboutUs;